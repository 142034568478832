import React, { useEffect, useState } from 'react';
import './TableComponent.style.css';
import './ClientPaymentModal.style.css';
import { Link } from 'react-router-dom';
import moment from 'moment';

import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
} from '@mui/material';
import AlertDialog from '../../../pages/AdvancePayment/deleteAlertDialog';
import { hasSuperAccess, hasTherapistClientCallerAccess } from '../../../utils/access';
import axios from 'axios';
import { getToken } from '../userLocalStorageUtils';
import { Close } from '@material-ui/icons';

const TableComponent = ({
  data,
  hiddenFields,
  showUpdateButton,
  viewButton,
  updateDetails,
  viewDetails,
  viewPackagesButton,
  viewBookingButton,
  bookingDetails,
  deleteCoupon,
  deleteCouponButton,
  deleteFAQ,
  deleteFAQButton,
  deletePaymentButton,
  deletePayment,
  verifyPaymentButton,
  verifyPayment,
  handleTransactionHistory,
  handleTransactionHistoryButton,
  handleTagButton,
  openModalButton,
  buttonList = [],
  editDisplay,
  handleEditToggle,
  handleValueChange,
  handleSubmit,
  isClientPayment,
  isIncentive,
  isClientCaller,
  callers,
  isTherapist,
}) => {
  const [open, setOpen] = useState(false);
  const [clientPaymentModel, setclientPaymentModel] = useState(false);
  const [currentClient, setcurrentClient] = useState(null);
  const [selectedCallers, setSelectedCallers] = useState({});
  const [selectedTherapistCallers, setSelectedTherapistCallers] = useState({})

  const [updatedRows, setUpdatedRows] = useState({});

  useEffect(() => {
    if (data && data.length > 0 && isClientCaller) {
      const initialCallers = {};
      data.forEach((row) => {
        initialCallers[row.orderId] = {
          id: row.added_by_user_id ? row.added_by_user_id || '' : '',
          name: row.added_by ? row.added_by || '' : '',
        };
      });
      setSelectedCallers(initialCallers);
    }
  }, [data, isClientCaller]);

  const handleCallerUpdate = async (rowId) => {
    const rowData = data.find((row) => row.orderId === rowId);

    if (rowData && rowData.manual_add_caller == 1) {
      alert('This order already has a manually assigned caller.');
      return;
    }

    // Don't proceed if no caller is selected
    if (!selectedCallers[rowId]?.id) {
      alert('Please select a caller first.');
      return;
    }

    try {
      const requestData = {
        order_id: rowId,

        razorpay_id: rowData.razorpayPaymentId,

        added_by: selectedCallers[rowId].name,
        user_id: selectedCallers[rowId].id,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/admin/reconcile/update-caller`,
        requestData,
        {
          headers: {
            Authorization: `Basic ${getToken()}`,
            token: getToken(),
          },
        },
      );

      if (response.data.success) {
        // Update the local state to reflect the change
        setSelectedCallers((prev) => ({
          ...prev,
          [rowId]: {
            ...prev[rowId],
            updated: true,
            isManual: true,
          },
        }));

        alert(`Successfully updated caller for order ${rowId}`);
        // Refresh the page after successful update
        window.location.reload();
      } else {
        console.error('API call failed:', response.data);
        alert('Failed to update caller. Please try again.');
      }
    } catch (error) {
      console.error('Error updating caller:', error);
      alert('An error occurred while updating the caller.');
    }
  };

  // Handle caller selection for a specific row
  const handleCallerChange = (rowId, callerId, callerName) => {
    console.log('===============', rowId, callerId, callerName);
    setSelectedCallers((prev) => ({
      ...prev,
      [rowId]: {
        id: callerId,
        name: callerName,
        updated: false,
      },
    }));
  };

  useEffect(() => {
    if (data && data.length > 0 && isTherapist) {
      const initialCallers = {};
      data.forEach((row) => {
        initialCallers[row.id] = {
          id: row.added_by_user_id ? row.added_by_user_id || '' : '',
          name: row.added_by ? row.added_by || '' : '',
        };
      });
      setSelectedTherapistCallers(initialCallers);
    }
  }, [data, isTherapist]);

  const handleTherapistCallerUpdate = async (rowId) => {
    const rowData = data.find((row) => row.id === rowId);

    if (rowData && rowData.manual_add_caller == 1) {
      alert('This order already has a manually assigned caller.');
      return;
    }

    // Don't proceed if no caller is selected
    if (!selectedTherapistCallers[rowId]?.callerId) {
      alert('Please select a caller first.');
      return;
    }
    console.log('rowId:', rowId);
    try {
      const requestData = {
        id: rowId,
        // Using orderId from rowData
        added_by: selectedTherapistCallers[rowId].name,
        user_id: selectedTherapistCallers[rowId].callerId,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/admin/reconcile/update-therapist-caller`,
        requestData,
        {
          headers: {
            Authorization: `Basic ${getToken()}`,
            token: getToken(),
          },
        },
      );

      if (response?.status == 200) {
        alert('updated successfully!!');
      }
    } catch (error) {
      console.error('Error updating caller:', error);
      alert('An error occurred while updating the caller.');
    }
  };
  // Handle caller selection for a specific row
  const handleTherapistCallerChange = (id, callerId, callerName) => {
    console.log('Selected Caller:', id, callerId, callerName);

    setSelectedTherapistCallers((prev) => ({
      ...prev,
      [id]: {
        // Use id as key to track selections correctly
        id: id, // Unique Row ID
        callerId: callerId, // Caller ID (selected option value)
        name: callerName, // Caller Name (selected option text)
        updated: false,
      },
    }));
  };

  const handleUpdate = (id) => {
    updateDetails(id);
  };

  const handleViewDetails = (details) => {
    viewDetails(details);
  };

  const handleViewBookingDetails = (details) => {
    bookingDetails(details);
  };
  const handlePackagesDetails = () => { };

  const deletePaymentHandler = (row) => {
    localStorage.setItem('deletePaymentRow', row.id);
    setOpen(true);
  };
  const handleOpenTagButton = (row) => {
    handleTagButton(row);
  };
  const stringifiedUser = localStorage.getItem('userData');
  const userData = stringifiedUser ? JSON.parse(stringifiedUser) : null;
  const id = JSON.parse(localStorage.getItem('userData'))?.user?.id;
  const concentrixUser =
    userData && userData.user && userData.user.concentrixUser
      ? userData.user.concentrixUser
      : false;

  return (
    <div className="table-container">
      {data && data.length > 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow className="table-header">
                {Object.keys(data[0]).map((key, index) => {
                  if (!hiddenFields || !hiddenFields.includes(key)) {
                    if (key == 'sessionSchedulesId') {
                      return (
                        <TableCell key={index} className="table-cell">
                          Service ID
                        </TableCell>
                      );
                    } else {
                      return (
                        <TableCell key={index} className="table-cell">
                          {key
                            .replace(/_/g, ' ')
                            .replace(/([a-z])([A-Z])/g, '$1 $2')
                            .replace(/\b\w/g, (l) => l.toUpperCase())}
                        </TableCell>
                      );
                    }
                  }
                  return null;
                })}

                {hasTherapistClientCallerAccess() && isClientCaller && (
                  <TableCell className="table-cell">Caller Claim</TableCell>
                )}
                {hasTherapistClientCallerAccess() && isTherapist && (
                  <TableCell className="table-cell">Caller claim</TableCell>
                )}
                {isIncentive && (
                  <TableCell className="table-cell">Time Difference</TableCell>
                )}
                {buttonList.map((item, index) => (
                  <TableCell className="table-cell">
                    {item.headerText}
                  </TableCell>
                ))}
                {viewButton && (
                  <TableCell className="table-cell">view</TableCell>
                )}
                {showUpdateButton && hasSuperAccess(id) && (
                  <TableCell className="table-cell">Actions</TableCell>
                )}
                {!concentrixUser && viewBookingButton && (
                  <TableCell className="table-cell">View</TableCell>
                )}
                {deleteCouponButton && (
                  <TableCell className="table-cell">Delete</TableCell>
                )}
                {deleteFAQButton && (
                  <TableCell className="table-cell">Delete</TableCell>
                )}
                {deletePaymentButton && (
                  <TableCell className="table-cell">Delete</TableCell>
                )}
                {verifyPaymentButton && (
                  <TableCell className="table-cell">Verify Payment</TableCell>
                )}
                {viewPackagesButton && (
                  <TableCell className="table-cell">View</TableCell>
                )}
                {handleTransactionHistoryButton && (
                  <TableCell className="table-cell">
                    Transaction History
                  </TableCell>
                )}
                {openModalButton && (
                  <TableCell className="table-cell">
                    {openModalButton}
                  </TableCell>
                )}
              </TableRow>
            </TableHead>

            <TableBody>
              {data.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {Object.keys(row).map((key, colIndex) => {
                    if (!hiddenFields || !hiddenFields.includes(key)) {
                      return (
                        <TableCell key={colIndex} className="table-cell">
                          {key === 'map' ? (
                            <Link to={row[key]} target="_blank">
                              Click here
                            </Link>
                          ) : key === 'image' || key === 'productImage' ? (
                            <img
                              className="view-unchecked-img"
                              src={`${row[key]}?w=100&h=100&fit=crop`}
                              onClick={() => window.open(row[key], '_blank')}
                              style={{
                                maxWidth: '100px',
                                maxHeight: '100px',
                                cursor: 'pointer',
                              }}
                              alt="img"
                            />
                          ) : key === 'packageDescription' ||
                            key === 'shortDescription' ||
                            key === 'ingredients' ? (
                            <div
                              dangerouslySetInnerHTML={{ __html: row[key] }}
                            />
                          ) : key === 'appointmentAt' ? (
                            moment(row[key], 'YYYY-MM-DDTHH:mm:ss.SSSZ')
                              .utcOffset('+00:00')
                              .format('DD-MM-YYYY, HH:mm A')
                          ) : key == 'createdAt' && isIncentive ? (
                            moment(row[key])
                              .utcOffset('+05:30')
                              .format('DD-MM-YYYY, HH:mm A')
                          ) : key === 'orderId' && isClientPayment ? (
                            <div
                              style={{
                                background: 'transparent',
                                border: 'none',
                                color: 'blue',
                                textDecoration: 'underline',
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                setclientPaymentModel(true);
                                setcurrentClient(row[key]);
                              }}
                            >
                              {row[key]}
                            </div>
                          ) : key === 'allottedIncentive' ? (
                            <div className="allotted-incentive-div">
                              {editDisplay[rowIndex].display ? (
                                <input
                                  type="number"
                                  value={editDisplay[rowIndex].value || 0}
                                  onChange={(e) =>
                                    handleValueChange(rowIndex, e.target.value)
                                  }
                                  min={0}
                                />
                              ) : (
                                row[key]
                              )}

                              <div className="allotted-edit-div">
                                {!editDisplay[rowIndex]?.display && (
                                  <button
                                    className="edit-button"
                                    onClick={() => handleEditToggle(rowIndex)}
                                  >
                                    Edit
                                  </button>
                                )}
                                {editDisplay[rowIndex]?.display && (
                                  <button
                                    className="edit-cancel"
                                    onClick={() => handleEditToggle(rowIndex)}
                                  >
                                    Cancel
                                  </button>
                                )}
                                {editDisplay[rowIndex]?.display && (
                                  <button
                                    className="edit-submit"
                                    onClick={() =>
                                      handleSubmit({
                                        partner_step_incentive_id:
                                          row['partnerStepIncentiveId'],
                                        amount: editDisplay[rowIndex].value,
                                      })
                                    }
                                  >
                                    Submit
                                  </button>
                                )}
                              </div>
                            </div>
                          ) : (
                            row[key]
                          )}
                        </TableCell>
                      );
                    }
                    return null;
                  })}
                  {hasTherapistClientCallerAccess() && isClientCaller && (
                    <TableCell className="table-cell">
                      <div className="caller-selection">
                        {row.added_by || row.added_by_user_id ? (
                          <div className="selected-caller-info">
                            <p>
                              Selected:{' '}
                              {row.added_by ||
                                selectedCallers[row.orderId]?.name}
                            </p>
                            <p className="caller-status">(Caller assigned)</p>
                          </div>
                        ) : (
                          <>
                            <select
                              name={`caller-${row.orderId}`}
                              id={`caller-${row.orderId}`}
                              onChange={(e) => {
                                const selectedOption =
                                  e.target.options[e.target.selectedIndex];
                                handleCallerChange(
                                  row.orderId,
                                  e.target.value,
                                  selectedOption.text,
                                );
                              }}
                              // disabled={
                              //   selectedCallers[row.orderId]?.updated ||
                              //   (row.added_by && row.added_by.trim() !== '') ||
                              //   (row.added_by_user_id &&
                              //     row.added_by_user_id.trim() !== '')
                              // }
                              value={
                                row.added_by ||
                                selectedCallers[row.orderId]?.id ||
                                ''
                              }
                            >
                              <option value="">Select Caller </option>
                              {callers.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => handleCallerUpdate(row.orderId)}
                              disabled={
                                !selectedCallers[row.orderId] ||
                                !selectedCallers[row.orderId].id ||
                                selectedCallers[row.orderId]?.updated ||
                                (row.added_by && row.added_by.trim() !== '') ||
                                (row.added_by_user_id &&
                                  row.added_by_user_id.trim() !== '')
                              }
                            >
                              {selectedCallers[row.orderId]?.updated
                                ? 'Updated'
                                : 'Update'}
                            </Button>
                          </>
                        )}
                      </div>
                    </TableCell>
                  )}
                  {hasTherapistClientCallerAccess() && isTherapist && (
                    <TableCell className="table-cell">
                      <div className="caller-selection">
                        {row.added_by || row.added_by_user_id ? (
                          <div className="selected-caller-info">
                            <p>
                              Selected:{' '}
                              {row.added_by ||
                                selectedTherapistCallers[row.orderId]?.name}
                            </p>
                            <p className="caller-status">(Caller assigned)</p>
                          </div>
                        ) : (
                          <>
                            <select
                              name={`caller-${row.id}`} // Use row.id instead of row.orderId
                              id={`caller-${row.id}`}
                              value={selectedTherapistCallers[row.id]?.callerId || ''} // Use row.id for tracking selected value
                              onChange={(e) => {
                                const selectedOption =
                                  e.target.options[e.target.selectedIndex];

                                handleTherapistCallerChange(
                                  row.id, // Unique row ID
                                  e.target.value, // Caller ID (from option value)
                                  selectedOption.text, // Caller Name (from option text)
                                );
                              }}
                            // disabled={
                            //   selectedCallers[row.id]?.updated ||
                            //   (row.added_by && row.added_by.trim() !== '') ||
                            //   (row.added_by_user_id &&
                            //     row.added_by_user_id.trim() !== '')
                            // }
                            >
                              <option value="">Select Caller</option>
                              {callers.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </select>

                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => handleTherapistCallerUpdate(row.id)}
                            // disabled={
                            //   !selectedCallers[row.orderId] ||
                            //   !selectedCallers[row.orderId].id ||
                            //   selectedCallers[row.orderId]?.updated ||
                            //   (row.added_by && row.added_by.trim() !== '') ||
                            //   (row.added_by_user_id &&
                            //     row.added_by_user_id.trim() !== '')
                            // }
                            >
                              {selectedTherapistCallers[row.orderId]?.updated
                                ? 'Updated'
                                : 'Update'}
                            </Button>
                          </>
                        )}
                      </div>
                    </TableCell>
                  )}
                  {isIncentive && (
                    <TableCell className="table-cell">
                      <td>
                        {rowIndex > 0
                          ? `${Math.floor(
                            moment(row.createdAt).diff(
                              moment(data[rowIndex - 1].createdAt),
                              'seconds',
                            ) / 60,
                          )}m ${moment(row.createdAt).diff(
                            moment(data[rowIndex - 1].createdAt),
                            'seconds',
                          ) % 60
                          }s`
                          : 'N/A'}
                      </td>
                    </TableCell>
                  )}

                  {buttonList.map((item, index) => (
                    <TableCell className="table-cell">
                      <Button
                        disabled={
                          item.id === 'payment_link' &&
                          row.payment_status === 'paid'
                        }
                        variant="contained"
                        color="primary"
                        onClick={() => item.onClick(row)}
                      >
                        {item.text}
                      </Button>
                    </TableCell>
                  ))}
                  {viewButton && (
                    <TableCell className="table-cell">
                      {viewButton === 'img' ? (
                        <img
                          className="view-unchecked-img"
                          src={`${row.image}?w=100&h=100&fit=crop`}
                          onClick={() => window.open(row.image, '_blank')}
                          alt="img"
                        />
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleViewDetails(row)}
                        >
                          {viewButton}
                        </Button>
                      )}
                    </TableCell>
                  )}
                  {showUpdateButton && hasSuperAccess(id) && (
                    <TableCell className="table-cell">
                      <Button
                        variant="contained"
                        style={{ backgroundColor: 'red', color: 'white' }}
                        onClick={() => handleUpdate(row)}
                      >
                        {showUpdateButton}
                      </Button>
                    </TableCell>
                  )}

                  {!concentrixUser
                    ? viewBookingButton && (
                      <TableCell className="table-cell">
                        {viewBookingButton === 'img' ? (
                          <img
                            className="view-unchecked-img"
                            src={`${row.image}?w=100&h=100&fit=crop`}
                            onClick={() => window.open(row.image, '_blank')}
                            alt="img"
                          />
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleViewBookingDetails(row)}
                          >
                            {viewBookingButton}
                          </Button>
                        )}
                      </TableCell>
                    )
                    : ''}

                  {viewPackagesButton && (
                    <TableCell className="table-cell">
                      {viewPackagesButton === 'img' ? (
                        <img
                          className="view-unchecked-img"
                          src={`${row.image}?w=100&h=100&fit=crop`}
                          onClick={() => window.open(row.image, '_blank')}
                          alt="img"
                        />
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handlePackagesDetails(row)}
                        >
                          {viewPackagesButton}
                        </Button>
                      )}
                    </TableCell>
                  )}
                  {deleteCouponButton && (
                    <TableCell className="table-cell">
                      {deleteCouponButton === 'img' ? (
                        <img
                          className="view-unchecked-img"
                          src={`${row.image}?w=100&h=100&fit=crop`}
                          onClick={() => window.open(row.image, '_blank')}
                          alt="img"
                        />
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ backgroundColor: '#D70040', color: 'white' }}
                          onClick={() => deleteCoupon(row)}
                        >
                          {deleteCouponButton}
                        </Button>
                      )}
                    </TableCell>
                  )}
                  {deletePaymentButton && (
                    <>
                      <TableCell className="table-cell">
                        {deletePaymentButton === 'img' ? (
                          <img
                            className="view-unchecked-img"
                            src={`${row.image}?w=100&h=100&fit=crop`}
                            onClick={() => window.open(row.image, '_blank')}
                            alt="img"
                          />
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            style={{
                              backgroundColor: '#D70040',
                              color: 'white',
                            }}
                            onClick={() => deletePaymentHandler(row)}
                          >
                            {deletePaymentButton}
                          </Button>
                        )}
                      </TableCell>
                      {open && (
                        <AlertDialog
                          open={open}
                          setOpen={setOpen}
                          deletePayment={deletePayment}
                        />
                      )}
                    </>
                  )}

                  {!row.isValidated && verifyPaymentButton && (
                    <TableCell className="table-cell">
                      {deletePaymentButton === 'img' ? (
                        <img
                          className="view-unchecked-img"
                          src={`${row.image}?w=100&h=100&fit=crop`}
                          onClick={() => window.open(row.image, '_blank')}
                          alt="img"
                        />
                      ) : (
                        <Button
                          variant="contained"
                          color="success"
                          style={{ backgroundColor: '#0d9149', color: 'white' }}
                          onClick={() => {
                            verifyPayment(row);
                          }}
                        >
                          {verifyPaymentButton}
                        </Button>
                      )}
                    </TableCell>
                  )}
                  {row.isValidated && verifyPaymentButton && (
                    <TableCell className="table-cell">
                      {deletePaymentButton === 'img' ? (
                        <img
                          className="view-unchecked-img"
                          src={`${row.image}?w=100&h=100&fit=crop`}
                          onClick={() => window.open(row.image, '_blank')}
                          alt="img"
                        />
                      ) : (
                        <Button
                          variant="contained"
                          color="success"
                          style={{ backgroundColor: '#0d9149', color: 'white' }}
                          disabled
                        >
                          {'Verified'}
                        </Button>
                      )}
                    </TableCell>
                  )}

                  {deleteFAQButton && (
                    <TableCell className="table-cell">
                      {deleteFAQButton === 'img' ? (
                        <img
                          className="view-unchecked-img"
                          src={`${row.image}?w=100&h=100&fit=crop`}
                          onClick={() => window.open(row.image, '_blank')}
                          alt="img"
                        />
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ backgroundColor: '#D70040', color: 'white' }}
                          onClick={() => deleteFAQ(row)}
                        >
                          {deleteFAQButton}
                        </Button>
                      )}
                    </TableCell>
                  )}
                  {handleTransactionHistoryButton && (
                    <TableCell className="table-cell">
                      {deletePaymentButton === 'img' ? (
                        <img
                          className="view-unchecked-img"
                          src={`${row.image}?w=100&h=100&fit=crop`}
                          onClick={() => window.open(row.image, '_blank')}
                          alt="img"
                        />
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ backgroundColor: '#0d9149', color: 'white' }}
                          onClick={() => handleTransactionHistory(row)}
                        >
                          {handleTransactionHistoryButton}
                        </Button>
                      )}
                    </TableCell>
                  )}
                  {openModalButton && (
                    <TableCell className="table-cell">
                      {openModalButton && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleOpenTagButton(row)}
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          {openModalButton}
                        </Button>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div className="no-data-found">
          <p>Data not found</p>
        </div>
      )}
      {clientPaymentModel && (
        <ClientPaymentModal
          orderId={currentClient}
          setclientPaymentModel={setclientPaymentModel}
        />
      )}
    </div>
  );
};

export default TableComponent;

const ClientPaymentModal = ({ orderId, setclientPaymentModel }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await axios
          .get(
            `${process.env.REACT_APP_API_URL}/api/v1/admin/reconcile/products-in-order/` +
            orderId,
            {
              headers: {
                Authorization: `Basic ${getToken()}`,
                token: getToken(),
              },
            },
          )
          .then((res) => res.data)
          .then((d) => {
            console.log(d);
            setData(d.orderDetails);
          });
      } catch (e) {
        setclientPaymentModel(false);
        alert('Error occured');
      }
    };

    fetchData();
  }, []);
  return (
    <div className="client-window">
      <div className="window">
        <button className="close" onClick={() => setclientPaymentModel(false)}>
          <Close />
        </button>
        {data && data.length > 0 && <TableComponent data={data} />}
      </div>
    </div>
  );
};

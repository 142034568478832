import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getToken } from "../../components/common/userLocalStorageUtils";

const apiUrl = process.env.REACT_APP_API_URL;

export const getTag = createAsyncThunk('tags/getTag', async (page = 1, { rejectWithValue }) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/admin/tag?page=${page}`, {
            headers: {
                Authorization: `Basic ${getToken()}`,
                token: getToken(),
            },
        });
        return res.data;
    } catch (error) {
        console.error('Error fetching tags:', error);
        return rejectWithValue(error.response?.data || 'Failed to fetch tags');
    }
});

export const getItemTag = createAsyncThunk('tags/itemTag', async ({ type, itemId }, { rejectWithValue }) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/admin/tag/get-item-tag/${type}/${itemId}`, {
            headers: {
                Authorization: `Basic ${getToken()}`,
                token: getToken(),
            },
        });
        return res.data?.itemTag;
    } catch (error) {
        console.error('Error fetching tags:', error);
        return rejectWithValue(error.response?.data || 'Failed to fetch tags');
    }
});


export const addTag = async (data) => {
    try {
        const res = await axios.post(`${apiUrl}/api/v1/admin/tag/create-tag`, data, {
            headers: {
                Authorization: `Basic ${getToken()}`,
                token: getToken(),
            },
        });
        return res;
    } catch (error) {
        return error;
    }
}


export const addOrDeleteTags = async (data) => {
    try {
        const res = await axios.post(`${apiUrl}/api/v1/admin/tag/add-or-delete-tag`, data, {
            headers: {
                Authorization: `Basic ${getToken()}`,
                token: getToken(),
            },
        });
        return res;
    } catch (error) {
        return error;
    }
}



export const deleteTag = async (id) => {
    try {
        const res = await axios.delete(`${apiUrl}/api/v1/admin/tag/${id}`, {
            headers: {
                Authorization: `Basic ${getToken()}`,
                token: getToken(),
            },
        });
        return res;
    } catch (error) {
        return error;
    }
}

export const updateTag = async (id, data) => {
    try {
        const res = await axios.put(`${apiUrl}/api/v1/admin/tag/${id}`, data, {
            headers: {
                Authorization: `Basic ${getToken()}`,
                token: getToken(),
            },
        });
        return res;
    } catch (error) {
        return error;
    }
}

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import LoaderComponent from '../../components/common/LoaderComponent/LoaderComponent';
import './OrderView.style.css';

import {
  Pagination,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
} from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import dayjs from 'dayjs';
import { Search } from '@mui/icons-material';
import {
  downloadPaymentData,
  getClientOrderDetails,
} from '../../store/actions/payment.action';
import ChangeStatus from './Common/ChangeStatus';
import ProofViewer from './Common/ProofViewer';

import DownloadIcon from '@mui/icons-material/Download';
import GetAppIcon from '@mui/icons-material/GetApp';
const getDefaultDates = () => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  return {
    startDate: yesterday.toISOString().split('T')[0],
    endDate: tomorrow.toISOString().split('T')[0],
  };
};
const PaymentProofInfo = () => {
  const navigate = useNavigate();
  const limit = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalAmount, setTotalAmount] = useState(0);
  const [client, setClient] = useState({
    clientList: [],
    totalClient: 0,
    isLoading: false,
    totalPages: 1,
  });
const { startDate: defaultStart, endDate: defaultEnd } = getDefaultDates();
  const [search, setSearch] = useState({
    type: '',
    text: '',
    searchClick: false,
    startDate: defaultStart,
    endDate: defaultEnd,
    status: '',
  });

  const user = JSON.parse(localStorage.getItem('userData'))?.user;

  const handlePageChange = (event, value) => {
    if (value >= 1 && value <= client.totalPages) {
      setCurrentPage(value);
    }
  };

  const getOrderDetails = async () => {
    setClient((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    try {
      const userEmail = user?.email;
      userEmail === "sumit.maurya@avataarskin.com" ? "APPROVED" : search.status;
      const response = await getClientOrderDetails(
        limit,
        currentPage,
        search.type,
        search.text,
        search.startDate,
        search.endDate,
        user.email,
        search.status,
       
      );

      setTotalAmount(response.totalAmount);

      setClient((prevState) => ({
        ...prevState,
        clientList: response.list,
        totalClient: response.total,
        totalPages: Math.ceil(response.total / limit),
        isLoading: false,
      }));
    } catch (error) {
      setClient((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }
  };

  useEffect(() => {
    // const debounceTimer = setTimeout(() => {
    getOrderDetails();
    // }, 100);

    // return () => {
    //   clearTimeout(debounceTimer);
    // };
  }, [
    currentPage,
    search.searchClick,
    search.text,
    search.startDate,
    search.endDate,
    search.status,
  ]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '30px',
        }}
      >
        <IconButton color="primary" onClick={() => navigate(-1)}>
          <KeyboardBackspaceIcon />
        </IconButton>
        <h3 style={{ margin: '0 auto' }}>Payment Info</h3>

        <h4>
          Total Amount: ₹
          {totalAmount ? parseInt(totalAmount).toLocaleString('en-IN') : 0}
        </h4>
      </div>
      <SearchComponent
        search={search}
        setSearch={setSearch}
        getData={getOrderDetails}
      />
      {client?.isLoading ? (
        <LoaderComponent />
      ) : (
        <>
          <OrderView data={client.clientList} />
        </>
      )}

      <div className="incentiv-pagination" style={{ marginTop: '1rem' }}>
        <Stack spacing={3}>
          <Pagination
            count={client.totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
          />
        </Stack>
      </div>
    </>
  );
};

export default PaymentProofInfo;

const SearchComponent = ({ search, setSearch, getData }) => {
  
  // Load stored filter type and status from localStorage on mount
  useEffect(() => {
    const storedType = localStorage.getItem("filterType");
    const storedStatus = localStorage.getItem("filterStatus");
    setSearch((prevState) => ({
      ...prevState,
      type: storedType || prevState.type, 
      status: storedStatus || prevState.status,
    }));
  }, [setSearch]);

  const handleSearch = () => {
    if (search.text.trim().length < 1) return;
    setSearch((prevState) => ({
      ...prevState,
      searchClick: !prevState.searchClick,
    }));
    getData();
  };

  const handleChange = (event) => {
    const type = event.target.value;
    localStorage.setItem("filterType", type);
    setSearch((prevState) => ({
      ...prevState,
      text: '',
      type: type,
    }));
  };

  const handleStatusChange = (event) => {
    const status = event.target.value;
    localStorage.setItem("filterStatus", status); 
    setSearch((prev) => ({
      ...prev,
      status: status,
    }));
  };

  const handleSearchOnClick = (event) => {
    if (search.text.trim().length < 1) return;
    if (event.key === "Enter") {
      event.preventDefault();
      setSearch((prevState) => ({
        ...prevState,
        searchClick: !prevState.searchClick,
      }));
      getData();
    }
  };

  const handleTextChange = (event) => {
    setSearch((prevState) => ({
      ...prevState,
      text: event.target.value,
    }));
  };

  const handleDateChange = (event) => {
    const { name, value } = event.target;
    setSearch((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const downloadData = async (event, format) => {
    event.preventDefault();
    if (!search.startDate || !search.endDate) {
      alert("Enter proper start and end date");
      return;
    }

    try {
      const data = await downloadPaymentData({
        start_date: search.startDate,
        end_date: search.endDate,
        payment_id: search.type === "paymentId" ? search.text : "",
        client_phone: search.type === "clientPhone" ? search.text : "",
        caller_email: search.type === "callerEmail" ? search.text : "",
      });

      if (data?.status.code === 200 && data?.downloadLinks) {
        const link = format === "csv" ? data.downloadLinks.csv : data.downloadLinks.excel;
        if (link) {
          window.location.href = link;
        } else {
          alert("No valid download link available");
        }
      } else {
        alert("Error occurred while generating download link");
      }
    } catch (error) {
      alert("Error occurred while generating download link");
    }
  };

  return (
    <div className="filter-window">
      <div className="window-1">
        <FormControl sx={{ minWidth: 200, mb: 2, mr: 2 }} size="small">
          <InputLabel id="filter-label">Filter Type</InputLabel>
          <Select
            labelId="filter-label"
            id="filter"
            value={search.type}
            label="searchType"
            onChange={handleChange}
          >
            <MenuItem value="paymentId">Payment Id</MenuItem>
            <MenuItem value="callerEmail">Caller Email</MenuItem>
            <MenuItem value="clientPhone">Client Phone</MenuItem>
          </Select>
        </FormControl>

        <TextField
          placeholder="Search"
          variant="outlined"
          size="small"
          value={search.text}
          type={search.type === "contactNumber" ? "number" : "text"}
          required
          onChange={handleTextChange}
          onKeyDown={handleSearchOnClick}
          InputProps={{
            endAdornment: (
              <IconButton color="primary" onClick={handleSearch}>
                <Search />
              </IconButton>
            ),
          }}
          sx={{ mb: 2 }}
        />
      </div>

      <select
        id="statusFilter"
        style={{
          width: "150px",
          padding: "5px",
          fontSize: "14px",
          border: "1px solid #ccc",
          borderRadius: "5px",
          backgroundColor: "#fff",
          cursor: "pointer",
          marginRight: "10px",
          marginTop: "20px",
        }}
        name="status"
        value={search.status} // Ensure controlled component
        onChange={handleStatusChange}
      >
        <option value="SUBMITTED">SUBMITTED</option>
        <option value="REJECTED">REJECTED</option>
        <option value="APPROVED">APPROVED</option>
        <option value="RECONCILIED">RECONCILIED</option>
      </select>

      <div className="window-2">
        <input
          type="date"
          name="startDate"
          value={search.startDate || ""}
          onChange={handleDateChange}
        />
        <input
          type="date"
          name="endDate"
          value={search.endDate || ""}
          onChange={handleDateChange}
          min={
            search.startDate
              ? new Date(new Date(search.startDate).getTime() + 86400000)
                  .toISOString()
                  .split("T")[0]
              : ""
          }
        />

        <Button
          variant="contained"
          color="primary"
          startIcon={<DownloadIcon />}
          onClick={(e) => downloadData(e, "csv")}
        >
          CSV
        </Button>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<GetAppIcon />}
          onClick={(e) => downloadData(e, "excel")}
        >
          Excel
        </Button>
      </div>
    </div>
  );
};

const OrderView = ({ data }) => {
  const user = JSON.parse(localStorage.getItem('userData'))?.user;
  const hasProofAccess = [
    'vaibhav.sharma@avataarskin.com',
    'suraj_00501@avataarskincare.com',
    'manish@avataarskincare.com'
  ].includes(user?.email);

  return (
    <div className="table-container">
      {data && data?.length > 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow className="table-header">
                <TableCell className="table-cell">Order Detail Id</TableCell>
                <TableCell className="table-cell">Client Name</TableCell>
                <TableCell className="table-cell">Client Phone</TableCell>
                <TableCell className="table-cell">Mode</TableCell>
                <TableCell className="table-cell">Payment Id</TableCell>
                <TableCell className="table-cell">Payment Date</TableCell>
                <TableCell className="table-cell">Total Actual Amount</TableCell>
                <TableCell className="table-cell">Discount Amount</TableCell>
                <TableCell className="table-cell">Total Order Amount</TableCell>
                <TableCell className="table-cell">Amount Paid</TableCell>
                <TableCell className="table-cell">Pending Amount</TableCell>
                <TableCell className="table-cell">Amount</TableCell>
                <TableCell className="table-cell">Status</TableCell>
                <TableCell className="table-cell">Submitted By</TableCell>
                <TableCell className="table-cell">Approved By</TableCell>
                <TableCell className="table-cell">Reconciled By</TableCell>
                {hasProofAccess && (
                  <TableCell className="table-cell">Proof</TableCell>
                )}
                <TableCell className="table-cell">Remarks</TableCell>
                <TableCell className="table-cell">Edit</TableCell>
              </TableRow>
              <TableRow></TableRow>
            </TableHead>

            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell className="table-cell">
                    {row.orderDetailId}
                  </TableCell>
                  <TableCell className="table-cell">{row.username}</TableCell>
                  <TableCell className="table-cell">
                    {!hasProofAccess ? row.phone.replace(/\d(?=\d{4})/g, '*'):row.phone}
                  </TableCell>
                  <TableCell className="table-cell">{row.mode}</TableCell>
                  <TableCell className="table-cell">{row.paymentId}</TableCell>
                  {/* <TableCell className="table-cell">
                    {row.paymentVendor}
                  </TableCell> */}
                  <TableCell className="table-cell">
                    {dayjs(row?.createdAt).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell className="table-cell">{row.totalActualAmount}</TableCell>
                  <TableCell className="table-cell">{row.discountAmount}</TableCell>
                  <TableCell className="table-cell">{row.totalOrderAmount}</TableCell>
                  <TableCell className="table-cell">{row.amountPaid}</TableCell>
                  <TableCell className="table-cell">{row.pendingAmount}</TableCell>
                  <TableCell className="table-cell">{row.amount}</TableCell>
                  <TableCell className="table-cell">{row.status}</TableCell>
                  <TableCell className="table-cell">
                    {row.submittedBy}
                  </TableCell>
                  <TableCell className="table-cell">{row.approvedBy}</TableCell>
                  <TableCell className="table-cell">
                    {row.reconciledBy}
                  </TableCell>
                  {hasProofAccess && (
                    <TableCell className="table-cell">
                      <ProofViewer files={row.proof} />
                    </TableCell>
                  )}
                  <TableCell className="table-cell">{row.remarks}</TableCell>
                  <TableCell className="table-cell">
                    <ChangeStatus row={row} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div className="no-data-found">
          <p>Data not found</p>
        </div>
      )}
    </div>
  );
};

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getToken } from "../../components/common/userLocalStorageUtils";

const apiUrl = process.env.REACT_APP_API_URL;

export const getClients = createAsyncThunk('client', async (params) => {
    try {
        const queryParams = new URLSearchParams(params).toString();
        const res = await
            axios.get(`${apiUrl}/api/v1/admin/client/clients?${queryParams}`,
                {
                    headers: {
                        Authorization: `Basic ${getToken()}`,
                        token: getToken(),
                    },
                }
            );
        const data = await res.data;
        return data;
    }
    catch (error) {
        throw error;
    }
});

export const getAllClients = createAsyncThunk('client', async (params) => {
    try {
        const queryParams = new URLSearchParams(params).toString();
        const res = await axios.get(`${apiUrl}/api/v1/admin/client/all-clients?${queryParams}`, {
            headers: {
                Authorization: `Basic ${getToken()}`,
                token: getToken(),
            },
        });
        const data = await res.data;
        return data;
    }
    catch (error) {
        throw error;
    }
});

export const UpdateClients = async (id, data) => {
    try {
        const res = await axios.patch(`${apiUrl}/api/v1/admin/client/clients/${id}`, data, {
            headers: {
                Authorization: `Basic ${getToken()}`,
                token: getToken(),
            },
        });
        const response = await res.data;
        return response;
    } catch (error) {
        throw error;
    }
}

export const DownloadClientData = async (params) => {
    try {
        const res = await
            axios.get(`${apiUrl}/api/v1/admin/client/downloadClient?startDate=${params.startDate}&endDate=${params.endDate}`,
                {
                    headers: {
                        Authorization: `Basic ${getToken()}`,
                        token: getToken(),
                    },
                }
            );
        const response = await res.data;
        return response;
    } catch (error) {
        throw error;
    }
}

export const DownloadAllClientData = async (params) => {
    try {
        const res = await
            axios.get(`${apiUrl}/api/v1/admin/client/download-all-clients?startDate=${params.startDate}&endDate=${params.endDate}`,
                {
                    headers: {
                        Authorization: `Basic ${getToken()}`,
                        token: getToken(),
                    },
                }
            );
        const response = await res.data;
        return response;
    } catch (error) {
        throw error;
    }
}

export const getClientById = async (id) => {
    try {
        const res = await
            axios.get(`${apiUrl}/api/v1/admin/client/getClient?id=${id}`,
                {
                    headers: {
                        Authorization: `Basic ${getToken()}`,
                        token: getToken(),
                    },
                }
            );
        const response = await res.data;
        return response;
    } catch (error) {
        throw error
    }
}

export const getHealth = async (id) => {
    try {
        const res = await
            axios.get(`${apiUrl}/api/v1/admin/client/getHealth`,
                {
                    headers: {
                        Authorization: `Basic ${getToken()}`,
                        token: getToken(),
                    },
                }
            );
        const response = await res.data;
        return response;
    } catch (error) {
        throw error
    }
}

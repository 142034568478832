import MachineChange from "./machineChange/machineChange";
import WeekOffManagement from "./Weekoffmanagement/Weekoffmanagement";
import App from "./statusChange/statusChange";
import TherapistChange from "./TherapistChange/therapistChange";
import DateTimeChange from "./dateTimeChange/dateTimeChange";

const ManualUpdates = () => {
  const stringifiedUser = localStorage.getItem('userData');
  const userData = stringifiedUser ? JSON.parse(stringifiedUser) : null;
  const if_the_user_is_vaibhav = userData?.user?.email === 'vaibhav.sharma@avataarskin.com'
  return (
    if_the_user_is_vaibhav? (<div style={styles.container}>
      <div style={styles.gridItem}><MachineChange /></div>
      <div style={styles.gridItem}><App /></div>
      <div style={styles.gridItem}><DateTimeChange /></div>
      <div style={styles.gridItem}><TherapistChange /></div>
      <div style={styles.gridItem}><WeekOffManagement /></div>
    </div>):<h1>🚷 Area Restricted</h1>
  );
};

const styles = {
  container: {
    display: "flex",
    flexWrap: "wrap", 
    gap: "20px", 
    padding: "20px",
    justifyContent: "center" 
  },
  gridItem: {
    border: "1px solid #ddd",
    padding: "15px",
    borderRadius: "8px",
    backgroundColor: "#f9f9f9",
    boxShadow: "2px 2px 10px rgba(0,0,0,0.1)",
    flex: "1 1 calc(33.333% - 20px)", 
    minWidth: "250px", 
    maxWidth: "400px" 
  }
};

export default ManualUpdates;


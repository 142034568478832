import React, { useState, useEffect } from 'react';
import {
  Container,
  TextField,
  Button,
  TablePagination,
  CircularProgress,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from '@mui/material';

import axios from 'axios';
import { getToken } from '../../components/common/userLocalStorageUtils';
import DownloadIcon from '@mui/icons-material/Download';
import GetAppIcon from '@mui/icons-material/GetApp';
import TableComponent from '../../components/common/TableComponent/TableComponent';
import { Search } from '@material-ui/icons';

// Function to get yesterday and tomorrow's date in YYYY-MM-DD format
const getDefaultDates = () => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  return {
    startDate: yesterday.toISOString().split('T')[0],
    endDate: tomorrow.toISOString().split('T')[0],
  };
};

const TherapistPaymentInfo = () => {
  const storedStartDate = localStorage.getItem('therapistStartDate');
  const storedEndDate = localStorage.getItem('therapistEndDate');
  const storedPhone = localStorage.getItem('therapistPhone');

  const { startDate: defaultStart, endDate: defaultEnd } = getDefaultDates();

  const [payments, setPayments] = useState([]);
  const [count, setCount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [startDate, setStartDate] = useState(storedStartDate || defaultStart);
  const [endDate, setEndDate] = useState(storedEndDate || defaultEnd);
  const [phone, setPhone] = useState(storedPhone || '');
  const [loading, setLoading] = useState(false);
  const [callers, setCallers] = useState([]);
  const isClientPayment = true;

  const [search, setSearch] = useState({
    type: '',
    text: '',
    searchClick: false,
    startDate: startDate,
    endDate: endDate,
  });

  const fetchPayments = async () => {
    if (!search.startDate || !search.endDate) return;

    setLoading(true);

    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/admin/reconcile/therapist-payments`,
        {
          params: {
            start_date: search.startDate,
            end_date: search.endDate,
            page: page + 1,
            limit,
            ...(search.type === 'therapistPhone'
              ? { therapist_phone: search.text }
              : { client_phone: search.text }),
          },
          headers: {
            Authorization: `Basic ${getToken()}`,
            token: getToken(),
          },
        },
      );

      setPayments(data.payments);
      setCount(data.count);
      setTotalAmount(data.totalAmount);
    } catch (error) {
      console.error('Error fetching therapist payments:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPayments();
  }, [page, limit]);
  const fetchCallers = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/admin/reconcile/get-caller`,
        {
          params: {
            start_date: search.startDate,
            end_date: search.endDate,
            page: page + 1,
            limit,
            ...(search.type === 'callerPhone'
              ? { caller_phone: search.text }
              : { client_phone: search.text }),
          },
          headers: {
            Authorization: `Basic ${getToken()}`,
            token: getToken(),
          },
        },
      );
      // console.log('caller data on front end is:', data.caller_data);
      setCallers(data.caller_data || []);
    } catch (error) {
      console.error('Error fetching callers:', error);
    }
  };
  useEffect(() => {
    fetchCallers();
  }, []);
  // Store filter values in localStorage when changed
  useEffect(() => {
    localStorage.setItem('therapistStartDate', startDate);
    localStorage.setItem('therapistEndDate', endDate);
    localStorage.setItem('therapistPhone', phone);
  }, [startDate, endDate, phone]);

  return (
    <Container>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h2 style={{ margin: '20px 0' }}>Therapists Payments</h2>
        <h4>
          Total Amount: ₹
          {totalAmount ? parseInt(totalAmount).toLocaleString('en-IN') : 0}
        </h4>
      </div>
      <SearchComponent
        search={search}
        setSearch={setSearch}
        getData={fetchPayments}
      />

      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="300px"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <TableComponent
            data={payments}
            isClientPayment={isClientPayment}
            isTherapist={true}
            callers={callers}
          />

          <TablePagination
            component="div"
            count={count}
            page={page}
            rowsPerPage={limit}
            onPageChange={(_, newPage) => {
              setPage(newPage);
              fetchPayments();
            }}
            onRowsPerPageChange={(e) => {
              setLimit(parseInt(e.target.value, 10));
              setPage(0);
              fetchPayments();
            }}
          />
        </>
      )}
    </Container>
  );
};

export default TherapistPaymentInfo;

const SearchComponent = ({ search, setSearch, getData }) => {
  const handleSearch = () => {
    if (search.text.trim().length < 1) {
      return;
    }
    setSearch((prevState) => ({
      ...prevState,
      searchClick: !prevState.searchClick,
    }));
    getData();
  };

  const handleChange = (event) => {
    const type = event.target.value;
    setSearch((prevState) => ({
      ...prevState,
      text: '',
      type: type,
    }));
  };

  const handleSearchOnClick = (e) => {
    if (search.text.trim().length < 1) {
      return;
    }
    if (e.key === 'Enter') {
      e.preventDefault();
      setSearch((prevState) => ({
        ...prevState,
        searchClick: !prevState.searchClick,
      }));
      getData();
    }
  };

  const handleTextChange = (e) => {
    const text = e.target.value;
    setSearch((prevState) => ({
      ...prevState,
      text: text,
    }));
  };

  const handleDateChange = (event) => {
    const { name, value } = event.target;

    if (name === 'startDate' || name === 'endDate') {
      setSearch((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const downloadData = async (e, format) => {
    try {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/v1/admin/reconcile/download-therapist-payments`,
          {
            params: {
              start_date: search.startDate,
              end_date: search.endDate,
            },
            headers: {
              Authorization: `Basic ${getToken()}`,
              token: getToken(),
            },
          },
        )
        .then((res) => res.data)
        .then((data) => {
          if (data?.status.code === 200 && data?.downloadLinks) {
            const link =
              format === 'csv'
                ? data.downloadLinks.csv
                : data.downloadLinks.excel;
            if (link) {
              window.location.href = link;
            } else {
              alert('No valid download link available');
            }
          } else {
            alert('Error occurred while generating download link');
          }
        });
    } catch (e) {
      alert('Error occurred while generating download link');
    }
  };

  useEffect(() => {
    getData();
  }, [search.startDate, search.endDate]);

  return (
    <div className="filter-window">
      <div className="window-1">
        <FormControl sx={{ minWidth: 200, mb: 2, mr: 2 }} size="small">
          <InputLabel id="filter-label">Filter Type</InputLabel>
          <Select
            labelId="filter-label"
            id="filter"
            value={search.type}
            label="searchType"
            onChange={handleChange}
          >
            <MenuItem value="therapistPhone">Therapist Phone</MenuItem>
            <MenuItem value="clientPhone">Client Phone</MenuItem>
          </Select>
        </FormControl>

        <TextField
          placeholder="Search"
          variant="outlined"
          size="small"
          value={search.text}
          type={search.type === 'contactNumber' ? 'number' : 'text'}
          required
          onChange={handleTextChange}
          onKeyDown={handleSearchOnClick}
          InputProps={{
            endAdornment: (
              <IconButton color="primary" onClick={handleSearch}>
                <Search />
              </IconButton>
            ),
          }}
          sx={{ mb: 2 }}
        />
      </div>
      <div className="window-2">
        <input
          type="date"
          name="startDate"
          value={search.startDate || ''}
          onChange={handleDateChange}
        />
        <input
          type="date"
          name="endDate"
          value={search.endDate || ''}
          onChange={handleDateChange}
          min={search.startDate}
        />

        <Button
          variant="contained"
          color="primary"
          startIcon={<DownloadIcon />}
          onClick={(e) => downloadData(e, 'csv')}
        >
          CSV
        </Button>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<GetAppIcon />}
          onClick={(e) => downloadData(e, 'excel')}
        >
          Excel
        </Button>
      </div>
    </div>
  );
};

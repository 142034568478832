import {
  Button,
  Box,
  Grid,
  Paper,
  TextField,
  Typography,
  MenuItem,
  IconButton,
  ListSubheader,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { hasAdminAccess } from '../../components/common/UserRolesConfig';
import {
  editPaymentInfo,
  getPaymentInfoById,
} from '../../store/actions/payment.action';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const STATUS = [
  { id: 'SUBMITTED', label: 'SUBMITTED' },
  { id: 'APPROVED', label: 'APPROVED' },
  { id: 'REJECTED', label: 'REJECTED' },
  { id: 'RECONCILIED', label: 'RECONCILIED' },
];

const userData = JSON.parse(localStorage.getItem('userData'))?.user;
const role = userData?.role;
const callerAccess = userData?.callerAccess;

const EditPaymentProofSubmittedByOrder = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formData, setFormData] = useState({
    mode: 'Cash',
    amount: '',
    orderDetailId: '',
    proof: [],
    files: [],
    // paymentVendor: '',
    paymentID: '',
    status: '',
    remarks: '',
  });

  const paymentInfo = async () => {
    try {
      const response = await getPaymentInfoById(id);
      const { data } = response;

      if (data) {
        setFormData((prevState) => ({
          ...prevState,
          mode: data?.mode,
          amount: data?.amount,
          orderDetailId: data?.order_detail_id,
          proof: [],
          files: [],
          //   paymentVendor: data?.payment_vendor,
          paymentID: data?.payment_id,
          status: data?.status,
          remarks: data?.remarks,
        }));
      } else {
        window.alert('Not Able to get data');
      }
    } catch (error) {
      console.error(error);
      window.alert('SomeThing went wrong');
    }
  };
  useEffect(() => {
    paymentInfo();
  }, [id]);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    let updatedFormData = { ...formData };
    const fileNames = [];
    if (type === 'file') {
      Array.from(files).forEach((file, index) => {
        fileNames.push(file.name);
      });
    }

    updatedFormData = {
      ...updatedFormData,
      [name]: type === 'file' ? files : value,
      ['files']: fileNames,
    };
    setFormData(updatedFormData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await editPaymentInfo(id, {
        mode: formData.mode,
        amount: parseFloat(formData.amount),
        // paymentVendor: formData.paymentVendor,
        paymentId: formData.paymentID,
        remarks: formData.remarks,
      });

      if (response) {
        window.alert('Payment updated successfully.');
        navigate(-1);
      } else {
        window.alert('Something went wrong.');
      }
    } catch (error) {
      alert('An error occurred. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Paper
      elevation={3}
      sx={{
        padding: 4,
        margin: 'auto',
        maxWidth: 800,
        marginTop: '50px',
        borderRadius: 2,
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <IconButton
          color="primary"
          onClick={() => navigate(-1)}
          sx={{ position: 'absolute', top: 0, left: 0 }}
        >
          <KeyboardBackspaceIcon />
        </IconButton>
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{ color: '#0077b6', fontWeight: 'bold' }}
        >
          Payment Proof
        </Typography>
      </Box>

      <Box component="form" marginTop={5} onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <FromField formData={formData} handleChange={handleChange} />

          {(hasAdminAccess(role) || callerAccess) && (
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting}
                sx={{ padding: '12px', fontWeight: 'bold', fontSize: '16px' }}
              >
                {isSubmitting ? 'Updatting' : 'Update'}
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
    </Paper>
  );
};
export default EditPaymentProofSubmittedByOrder;

const FromField = ({ formData, handleChange }) => {
  return (
    <>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Order Detail Id"
          name="orderDetailId"
          value={formData.orderDetailId}
          disabled
          size="small"
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          select
          label="Mode"
          name="mode"
          size="small"
          onChange={handleChange}
          value={formData.mode}
        >
          <MenuItem value={'UPI'}>UPI</MenuItem>
          <MenuItem value={'UPI (Razorpay)'}>UPI (Razorpay)</MenuItem>
          <MenuItem value={'Cash/Therapist'}>Cash/Therapist</MenuItem>
          {/* <MenuItem value={'Therapist'}>Therapist</MenuItem> */}
          <MenuItem value={'App/Website'}>App/Website</MenuItem>
          {/* <MenuItem value={'Website'}>Website</MenuItem> */}
          <ListSubheader>EMI</ListSubheader>
          <MenuItem value={'Savein'}>Savein</MenuItem>
          <MenuItem value={'Zerope'}>Zerope</MenuItem>
          <MenuItem value={'Bajaj Finance'}>Bajaj Finance</MenuItem>
          <MenuItem value={'G Money'}>G Money</MenuItem>
        </TextField>
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Amount"
          name="amount"
          type="number"
          value={formData.amount}
          onChange={handleChange}
          required
          size="small"
          inputProps={{
            min: 1,
          }}
        />
      </Grid>

      {/* <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    label="Payment Vendor"
                    name="paymentVendor"
                    value={formData.paymentVendor}
                    onChange={handleChange}
                    size="small"
                />
            </Grid> */}

      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Payment ID"
          name="paymentID"
          disabled
          value={formData.paymentID}
          onChange={handleChange}
          required={formData.mode !== 'Cash'}
          size="small"
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          select
          label="status"
          name="status"
          size="small"
          onChange={handleChange}
          value={formData.status}
          disabled
        >
          {STATUS.map((item) => (
            <MenuItem
              key={item.id}
              value={item.id}
              disabled={item.id !== 'SUBMITTED' && callerAccess}
            >
              {item.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} md={12}>
        <TextField
          required
          fullWidth
          multiline
          rows={2}
          label="Remarks"
          name="remarks"
          size="small"
          onChange={handleChange}
          value={formData.remarks}
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <Button variant="contained" component="label">
          Upload File
          <input
            type="file"
            name="proof"
            accept=".png, .jpg, .jpeg, .pdf, .doc, .txt, .js"
            onChange={handleChange}
            hidden
            multiple
          />
        </Button>

        {formData?.files.map((file, item) => (
          <div key={item}>{file}</div>
        ))}
      </Grid>
    </>
  );
};

import React, { useState } from 'react';
import { TextField, Button, Box } from '@mui/material';
import axios from 'axios';
import ShowBookingTable from './ShowBookingTable';
import { getToken } from '../../components/common/userLocalStorageUtils';
import { ToastContainer } from 'react-toastify';

import CircularProgress from '@mui/material/CircularProgress';

const ClientForm = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [clientNumber, setClientNumber] = useState('');
  const [responseData, setResponseData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const styles = {
    containerStyles: {
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
      mx: 'auto',
      mt: 4,
    },
    textFieldStyles: {
      width: '30vw',
      margin: '0 auto',
      '& input[type=number]': {
        MozAppearance: 'textfield',
      },
      '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button':
      {
        WebkitAppearance: 'none',
        margin: 0,
      },
    },
    btnStyles: { width: '30vw', margin: '0 auto 20px' },
    errStyles: { color: 'red', textAlign: 'center' },
    loaderBoxStyles: { display: 'flex' },
    loaderStyles: { marginX: 'auto' },
  };

  const fetchShowBookingsData = async (phoneNumber) => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/v1/admin/booking/show-booking?phoneNumber=${encodeURIComponent(
          phoneNumber,
        )}`,
        {
          headers: {
            Authorization: `Basic ${getToken()}`,
            token: getToken(),
          },
        },
      );
      return response.data;
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to fetch data.');
      return null;
    }
  };

  const handleChange = (e) => {
    setClientNumber(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setResponseData(null);
    if (!clientNumber || clientNumber.length < 10) {
      setError('Please enter a valid phone number.');
      return;
    }

    setIsLoading(true);

    try {
      const data = await fetchShowBookingsData(clientNumber);
      setResponseData(data);
    } catch (err) {
      setError(err);
    }

    setIsLoading(false);
    setError(null);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={styles.containerStyles}>
      <TextField
        sx={styles.textFieldStyles}
        label="Client Number"
        name="clientNumber"
        type="number"
        value={clientNumber}
        onChange={handleChange}
        required
      />
      <Button type="submit" variant="contained" sx={styles.btnStyles}>
        Submit
      </Button>
      {error && <p style={styles.errStyles}>{error}</p>}

      {isLoading ? (
        <Box sx={styles.loaderBoxStyles}>
          <CircularProgress size="70px" sx={styles.loaderStyles} />
        </Box>
      ) : (
        <ShowBookingTable responseData={responseData} />
      )}
    </Box>
  );
};

export default ClientForm;

import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { getToken } from '../../components/common/userLocalStorageUtils';
import { toast, ToastContainer } from 'react-toastify';
import { Button } from '@mui/material';

const userData = JSON.parse(localStorage.getItem('userData'));
const styles = {
  tableCellStyles: { fontWeight: 'bold', color: '#4e4e4e' },
  btnStyles: { padding: '7px 15px', marginBottom: '7px' },
  tableRowStyles: { '&:last-child td, &:last-child th': { border: 0 } },
  tableCellFontStyles: { fontWeight: 'bold' },
  tableStyles: { minWidth: 650 },
  noData: { textAlign: 'center', color: 'red', width: '100%' },
};
const admin_id = userData?.user?.id;
const admin_name = userData?.user?.name;

function generateArray(minVal, maxVal) {
  return Array.from({ length: maxVal - minVal + 1 }, (_, i) => minVal + i);
}
const apiUrl = process.env.REACT_APP_API_URL;
const ShowBookingTable = ({ responseData }) => {
  const [updatedCount, setUpdatedCount] = useState({});

  useEffect(() => {
    if (
      responseData &&
      responseData?.bookings &&
      responseData?.bookings?.length > 0
    ) {
      const initialCounts = responseData.bookings.reduce((acc, row) => {
        acc[row.session_id] = row?.completedCount;
        return acc;
      }, {});
      setUpdatedCount(initialCounts);
    }
  }, [responseData]);

  const updateInDb = async (session_id, updatedCountValue) => {
    try {
      const passedParams = {
        updatedCount: updatedCountValue,
        session_id: session_id,
        admin_id: admin_id,
        admin_name: admin_name,
      };
      const response = await axios.patch(
        `${apiUrl}/api/v1/admin/booking/show-booking`,
        null,
        {
          params: passedParams,
          headers: {
            Authorization: `Basic ${getToken()}`,
            token: getToken(),
          },
        },
      );
      return response;
    } catch (error) {
      console.error('Error updating in DB:', error);
    }
  };

  const handleUpdateBtnClick = async (e, session_id) => {
    e.preventDefault();
    if (updatedCount[session_id]) {
      const apiResponse = await updateInDb(
        session_id,
        updatedCount[session_id],
      );

      if (apiResponse.status == 200) {
        toast.success(`Updated Count changed to ${updatedCount[session_id]}`);
      } else {
        toast.error('Something went wrong');
      }
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={styles.tableStyles} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={styles.tableCellStyles}>CLIENT NAME</TableCell>
            <TableCell sx={styles.tableCellStyles}>SESSION ID</TableCell>
            <TableCell sx={styles.tableCellStyles}>PHONE NUMBER</TableCell>
            <TableCell sx={styles.tableCellStyles}>GENDER</TableCell>
            <TableCell sx={styles.tableCellStyles}>ADDRESS</TableCell>
            <TableCell sx={styles.tableCellStyles}>PRODUCT NAME</TableCell>
            <TableCell sx={styles.tableCellStyles}>PRODUCT GENDER</TableCell>
            <TableCell sx={styles.tableCellStyles}>TOTAL SESSIONS</TableCell>
            <TableCell sx={styles.tableCellStyles}>
              COMPLETED SESSIONS
            </TableCell>
            <TableCell sx={styles.tableCellFontStyles}>UPDATED COUNT</TableCell>
            <TableCell sx={styles.tableCellFontStyles}>UPDATE VALUE</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {responseData &&
            responseData?.bookings &&
            responseData?.bookings?.length > 0
            ? responseData?.bookings.map((row, index) => (
              <TableRow key={index} sx={styles.tableRowStyles}>
                <TableCell>{row?.user_name}</TableCell>
                <TableCell>{row?.session_id}</TableCell>
                <TableCell>{row?.phone_number}</TableCell>
                <TableCell>{row?.user_gender}</TableCell>
                <TableCell>{row?.formatted_address}</TableCell>
                <TableCell>{row?.name}</TableCell>
                <TableCell>{row?.product_gender}</TableCell>
                <TableCell>{row?.count}</TableCell>
                <TableCell>{row?.completedCount}</TableCell>
                <TableCell>
                  <select
                    name="updated_count"
                    id="updated_count"
                    onChange={(e) =>
                      setUpdatedCount((prevState) => ({
                        ...prevState,
                        [row?.session_id]: e.target.value,
                      }))
                    }
                  >
                    {generateArray(row?.completedCount, row?.count).map(
                      (cnt) => (
                        <option key={cnt} value={cnt}>
                          {cnt}
                        </option>
                      ),
                    )}
                  </select>
                </TableCell>
                <TableCell>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={styles.btnStyles}
                    onClick={(e) => handleUpdateBtnClick(e, row?.session_id)}
                    disabled={
                      !updatedCount[row?.session_id] ||
                      updatedCount[row?.session_id] === '0'
                    }
                  >
                    UPDATE
                  </Button>
                </TableCell>
              </TableRow>
            ))
            : null}
        </TableBody>
      </Table>
      {responseData &&
        responseData?.bookings &&
        responseData?.bookings?.length > 0 ? null : (
        <h1 style={styles.noData}>NO DATA FOUND !</h1>
      )}
    </TableContainer>
  );
};

export default ShowBookingTable;

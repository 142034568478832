import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  Box,
  Container,
  Pagination,
  CircularProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getToken } from '../../components/common/userLocalStorageUtils';

function MissedBookings() {
  const navigate = useNavigate();

  const [pageData, setPageData] = useState({
    missedBookings: [],
    currentPage: 1,
    totalPages: 1,
    totalCount: 0,
    loading: false,
  });

  const fetchBookings = async () => {
    setPageData((prev) => ({ ...prev, loading: true }));

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/admin/missed-bookings/bookings`,
        {
          headers: {
            Authorization: `Basic ${getToken()}`,
            token: getToken(),
          },
          params: {
            page: pageData?.currentPage,
            limit: 10,
          },
        },
      );

      // Set the bookings, pagination info
      setPageData((prev) => ({
        ...prev,
        missedBookings: response?.data?.bookings,
        totalCount: response?.data?.pagination?.totalCount,
        totalPages: response?.data?.pagination?.totalPages,
        loading: false,
      }));
    } catch (error) {
      console.error('Error fetching data:', error);
      setPageData((prev) => ({ ...prev, loading: false }));
    }
  };
  useEffect(() => {
    fetchBookings();
  }, [pageData?.currentPage]);

  const handleViewBooking = (id) => {
    navigate(`/booking-details/${id}`);
  };

  const handlePageChange = (event, value) => {
    setPageData((prev) => ({ ...prev, currentPage: value }));
  };

  return (
    <Container>
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Missed Bookings
        </Typography>
        {pageData.loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            my={4}
          >
            <CircularProgress /> {/* Show a loading spinner while fetching */}
          </Box>
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Client Name</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>Product Name</TableCell>
                    <TableCell>City</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pageData.missedBookings?.map((booking) => (
                    <TableRow key={booking?.id}>
                      <TableCell>{booking?.id}</TableCell>
                      <TableCell>{booking?.clientName}</TableCell>
                      <TableCell>{booking?.phone}</TableCell>
                      <TableCell>{booking?.productName}</TableCell>
                      <TableCell>{booking?.city}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleViewBooking(booking.id)}
                        >
                          View Booking
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            {/* Pagination */}
            <Box mt={2} display="flex" justifyContent="center">
              <Pagination
                count={pageData?.totalPages}
                page={pageData?.currentPage}
                onChange={handlePageChange}
                color="primary"
                shape="rounded"
              />
            </Box>
          </>
        )}
      </Box>
    </Container>
  );
}

export default MissedBookings;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast,ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Login.style.css";
import logoImage from "../../../src/assets/images/avataar_logo_black.png";
import PhoneIcon from "@mui/icons-material/Phone";
import LockIcon from "@mui/icons-material/Lock";

const LogIn = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState({ phone: "", otp: "" });
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };


  useEffect(() => {
    const localUser = JSON.parse(localStorage.getItem('userData'));
    console.log(localUser)
    if (localUser) {
      if (localUser?.user?.callerAccess) {
        navigate('/slots-info');
      } else {
        navigate('/booking');
      }
    }
  }, [navigate]);

  // Function to send OTP
  const handleSendOtp = async () => {
    if (!/^\d{10}$/.test(user.phone)) {
      toast.error("Please enter a valid 10-digit phone number.");
      return;
    }

    try {
      setLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/auth/login-by-phone`, {
        phone: user.phone,
      });

      if (response.data.status.code === 200) {
        toast.success(response.data.status.message);
        setIsOtpSent(true);
      } else {
        toast.error(response.data.status.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.status?.message ?? "Failed to send OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Function to verify OTP
  const handleVerifyOtp = async () => {
    if (!/^\d{4}$/.test(user.otp)) {
      toast.error("Please enter a valid 4-digit OTP.");
      return;
    }

    try {
      setLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/auth/verify-login-otp`, {
        phone: user.phone,
        otp: user.otp,
      });
      // console.log("-------", response, response.data)

      if (response.data.status.code === 200) {
        toast.success(response.data.status.message);
        localStorage.setItem("userData", JSON.stringify(response.data));

        if (response.data.user.callerAccess) {
          navigate("/slots-info");
        } else {
          window.location.href = "/booking"; // Redirect & refresh
        }
      } else {
        toast.error(response.data.status.message);
      }
    } catch (error) {
      toast.error("Invalid OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <div className="image-form-container">
        <img src={logoImage} alt="avataar_logo" className="logo-image" />

        <form className="login-form" onSubmit={(e) => e.preventDefault()}>
          {/* Phone Input */}
          <div className="input-container">
            <label htmlFor="phone">Phone Number</label>
            <div className="input-with-icon">
              <PhoneIcon className="input-icon" />
              <input
                name="phone"
                type="text"
                placeholder="Enter your phone number"
                required
                value={user.phone}
                onChange={handleChange}
                maxLength={10}
              />
            </div>
          </div>

          {/* OTP Input (Visible After OTP Sent) */}
          {isOtpSent && (
            <div className="input-container">
              <label htmlFor="otp">Enter OTP</label>
              <div className="input-with-icon">
                <LockIcon className="input-icon" />
                <input
                  name="otp"
                  type="text"
                  placeholder="Enter OTP"
                  required
                  value={user.otp}
                  onChange={handleChange}
                  maxLength={4}
                />
              </div>
            </div>
          )}

          {/* Buttons */}
          {!isOtpSent ? (
            <button type="button" className="login-button" onClick={handleSendOtp} disabled={loading}>
              {loading ? "Sending OTP..." : "Send OTP"}
            </button>
          ) : (
            <button type="button" className="login-button" onClick={handleVerifyOtp} disabled={loading}>
              {loading ? "Verifying..." : "Verify OTP"}
            </button>
          )}
        </form>
      </div>
      <ToastContainer/>
    </div>
  );
};

export default LogIn;

import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  MenuItem,
  Select,
} from '@mui/material';
import LoaderComponent from '../../../components/common/LoaderComponent/LoaderComponent';
import { TablePagination } from '@mui/material';
import { getToken } from '../../../components/common/userLocalStorageUtils';
import axios from 'axios';

const sampleData = [
  {
    orderId: 'dwfjgvr',
    productDetails: ['ABC', 'EFR'],
    totalPrice: 1620,
    quantity: [3, 4],
    formattedAddress: 'ndcjwfeh,djk3frht,dwfhekgut4',
    status: 'RECEIVED',
    clientName: 'Varun',
    clientNumber: '8860905550',
    clientId: 123,
    orderDate: '2024-11-11',
  },
  {
    orderId: 'sdcdsdv',
    productName: ['ABC'],
    totalPrice: 1020,
    quantity: [3],
    formattedAddress: 'ndcjwfeh,dwfhekgut4',
    status: 'DISPATCHED',
    clientName: 'Vaibhav',
    clientNumber: '6969696969',
    clientId: 12,
    orderDate: '2024-09-23',
  },
  {
    orderId: 'dwfsvsjgvr',
    productName: ['ABC', 'EFR', 'fejgtr'],
    totalPrice: 2620,
    quantity: [3, 4, 6],
    formattedAddress: 'ndcjwfeh,djk3frht',
    status: 'DELIVERED',
    clientName: 'Varu Gn',
    clientNumber: '8866705550',
    clientId: 13,
    orderDate: '2024-11-10',
  }
];

const SkincareProductsBookings = () => {
  const storedStartDate =
    sessionStorage.getItem('bookingStartDate') ||
    today.toISOString().split('T')[0];
  const storedEndDate =
    sessionStorage.getItem('bookingEndDate') ||
    tomorrow.toISOString().split('T')[0];
  const [data, setData] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [startDate, setStartDate] = useState(storedStartDate);
  const [endDate, setEndDate] = useState(storedEndDate);
  const [page, setPage] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleStatusFilter = (event) => {
    setStatusFilter(event.target.value);
  };

  const handleDateChange = (event) => {
    if (event.target.name === 'startDate') {
      setStartDate(event.target.value);
    } else if (event.target.name === 'endDate') {
      setEndDate(event.target.value);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleStatusChange = (event, orderId) => {
    // const newStatus = event.target.value;
    // setData((prevData) =>
    //   prevData.map((item) =>
    //     item.orderId === orderId ? { ...item, status: newStatus } : item,
    //   ),
    // );
    axios.post(`${process.env.REACT_APP_API_URL}/api/v1/admin/skincare/change-status`, {
      orderId,
      status: event.target.value.toLowerCase()
    }, {
      headers: {
        Authorization: `Basic ${getToken()}`,
        token: getToken(),
      }
    })
      .then(res => {
        window.location.reload()
      })
      .catch(error => console.error('Error fetching data:', error));
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 3000);

  };

  const filteredData = data?.filter(
    (item) =>
      (item.clientName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.clientNumber.includes(searchTerm)) &&
      (statusFilter ? item.status === statusFilter : true) &&
      (startDate ? new Date(item.orderDate) >= new Date(startDate) : true) &&
      (endDate ? new Date(item.orderDate) <= new Date(endDate) : true),
  );

  const fetchData = async () => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/v1/admin/skincare/get-all-order`, {
      headers: {
        Authorization: `Basic ${getToken()}`,
        token: getToken(),
      }
    })
      .then(res => setData(res.data.orders))
      .catch(error => console.error('Error fetching data:', error));
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 3000);
  };

  const getStatusBackgroundColor = (status) => {
    switch (status) {
      case 'delivered':
        return 'rgba(0, 255, 0, 0.5)';
      case 'dispatch':
        return 'rgba(255, 165, 0, 0.5)';
      default:
        return 'transparent';
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Paper>
      {!isLoading && <div
        style={{ padding: 16, display: 'flex', gap: '16px', flexWrap: 'wrap' }}
      >
        <TextField
          label="Search by Name or Number"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearch}
        />
        <TextField
          label="Filter by Status"
          variant="outlined"
          select
          value={statusFilter}
          onChange={handleStatusFilter}
          style={{ width: '150px' }}
        >
          <MenuItem value="">All</MenuItem>
          <MenuItem value="recieved">recieved</MenuItem>
          <MenuItem value="dispatch">dispatch</MenuItem>
          <MenuItem value="delivered">delivered</MenuItem>
        </TextField>
        <div style={{ display: 'flex', gap: '16px' }}>
          <input
            type="date"
            name="startDate"
            value={startDate}
            onChange={handleDateChange}
          />
          <input
            type="date"
            name="endDate"
            value={endDate}
            onChange={handleDateChange}
            // min={new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString().split('T')[0]}
            min={
              new Date(new Date(startDate).getTime() + 24 * 60 * 60 * 1000)
                .toISOString()
                .split('T')[0]
            }
          />
        </div>
      </div>}
      {filteredData?.length === 0 && !isLoading && (
        <p className="centered-text">
          No Data found, please try reducing the filters and try again!
        </p>
      )}
      {isLoading && <LoaderComponent />}
      {!isLoading && filteredData?.length > 0 && (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow style={{ backgroundColor: '#E5E4E2' }}>
                  <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                    OrderId
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                    Client Name
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                    Client Number
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                    Client ID
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                    Client Address
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                    Product Name - Quantity
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                    Order Date
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                    Total Price
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow
                      key={index}
                      style={{
                        backgroundColor: getStatusBackgroundColor(row.status),
                      }}
                    >
                      <TableCell style={{ fontSize: '16px' }}>
                        {row.orderId}
                      </TableCell>
                      <TableCell style={{ fontSize: '16px' }}>
                        {row.clientName}
                      </TableCell>
                      <TableCell style={{ fontSize: '16px' }}>
                        {row.clientNumber}
                      </TableCell>
                      <TableCell style={{ fontSize: '16px' }}>
                        {row.clientId}
                      </TableCell>
                      <TableCell style={{ fontSize: '16px' }}>
                        {row.formattedAddress}
                      </TableCell>
                      <TableCell style={{ fontSize: '16px' }}>
                        {row.productDetails}
                      </TableCell>
                      <TableCell style={{ fontSize: '16px' }}>
                        {row.orderDate}
                      </TableCell>
                      <TableCell style={{ fontSize: '16px' }}>
                        {row.totalPrice}
                      </TableCell>
                      <TableCell style={{ fontSize: '16px' }}>
                        {['recieved', 'dispatch'].includes(row.status) ? (
                          <Select
                            value={row.status}
                            onChange={(event) =>
                              handleStatusChange(event, row.orderId)
                            }
                            style={{ width: '150px' }}
                          >
                            <MenuItem value="recieved">RECEIVED</MenuItem>
                            <MenuItem value="dispatch">DISPATCHED</MenuItem>
                            <MenuItem value="delivered">DELIVERED</MenuItem>
                          </Select>
                        ) : (
                          row.status
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredData?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </Paper>
  );
};

export default SkincareProductsBookings;

import React, { useState, useEffect } from "react";
import { Grid, TextField, Button, MenuItem, Container, FormControl, InputLabel, Select } from "@mui/material";
import axios from "axios";
import { getToken } from "../../../components/common/userLocalStorageUtils";
import { fetchCenter } from "../../../store/actions/center.action";
import { useDispatch, useSelector } from "react-redux";

const API_URL = process.env.REACT_APP_API_URL;

const TherapistChange = () => {
  const [bookingId, setBookingId] = useState("");
  const [selectedCenter, setSelectedCenter] = useState("");
  const [selectedTherapist, setSelectedTherapist] = useState("");
  const [therapistList, setTherapistList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [therapistName, setTherapistName] = useState("");

  const centers = useSelector(state => state.center?.centerList?.centers || []);
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(fetchCenter());
  }, [dispatch]);

  useEffect(() => {
    if (!selectedCenter) {
      setTherapistList([]);
      return;
    }

    const fetchTherapists = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/api/v1/admin/tech/therapist-by-center/${selectedCenter}`,
          { headers: { token: getToken(), Authorization: `Basic ${getToken()}`, } }
        );

        if (response?.status === 200 && response?.data?.therapists) {
          setTherapistList(response.data.therapists);
        } else {
          setTherapistList([]);
          alert("No therapists found or failed to fetch therapists.");
        }
      } catch (error) {
        console.error("Error fetching therapists:", error);
        alert("Failed to fetch therapists. Please try again later.");
        setTherapistList([]);
      }
    };

    fetchTherapists();
  }, [selectedCenter]);

  useEffect(() => {
    if (therapistName && therapistList.length > 0) {
      const foundTherapist = therapistList.find(t => t.name === therapistName);
      if (foundTherapist) {
        setSelectedTherapist(foundTherapist.id);
      }
    }
  }, [therapistList, therapistName]);


  const handleCheckBooking = async () => {
    if (!bookingId) {
      alert("Please enter a Booking ID first.");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/admin/booking/booking-details/${bookingId}`,
        {
          headers: { token: getToken(), Authorization: `Basic ${getToken()}`, },
        }
      );

      if (response?.status === 200 && response.data) {
        const bookingDetails = response.data;

        // Extract center ID and therapist name
        const centerId = bookingDetails.partnerDetail?.center_id;
        const therapistNameFromAPI = bookingDetails.partnerDetail?.name;

        if (centerId) {
          setSelectedCenter(centerId);
        }

        if (therapistNameFromAPI) {
          setTherapistName(therapistNameFromAPI);
        }
      } else {
        alert("Booking details not found.");
      }
    } catch (error) {
      console.error("Error fetching booking details:", error);
      alert("Failed to fetch booking details. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!bookingId || !selectedCenter || !selectedTherapist) {
      alert("Please fill in all fields before submitting.");
      return;
    }

    try {
      const payload = {
        sessionScheduleId: Number(bookingId),
        therapistId: Number(selectedTherapist),
      };

      const response = await axios.post(
        `${API_URL}/api/v1/admin/tech/assign-therapist`,
        payload,
        {
          headers: {
            Authorization: `Basic ${getToken()}`,
            token: getToken(),
            "Content-Type": "application/json",
          },
        }
      );

      if (response?.status === 200) {
        alert("Therapist assigned successfully!");
      } else {
        throw new Error("Failed to assign therapist.");
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      alert(error.response?.data?.message || "Failed to assign therapist. Please try again later.");
    }
  };

  return (
    <Container maxWidth="md">
      <div style={{ textAlign: "center", marginBottom: "20px" }}>
        <h2>Assign Therapist</h2>
      </div>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={6}>
            <TextField
              label="Booking ID"
              type="text"
              value={bookingId}
              onChange={(e) => setBookingId(e.target.value.replace(/\D/, ""))}
              fullWidth
              placeholder="Enter your booking ID"
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCheckBooking}
              disabled={loading}
            >
              {loading ? "Checking..." : "Check"}
            </Button>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Center</InputLabel>
              <Select
                value={selectedCenter}
                onChange={(e) => setSelectedCenter(e.target.value)}
                label="Center"
              >
                <MenuItem value="" disabled>
                  Select a Center
                </MenuItem>
                {centers.map((center) => (
                  <MenuItem key={center.id} value={center.id}>
                    {center.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined" disabled={!selectedCenter || therapistList.length === 0}>
              <InputLabel>Therapist</InputLabel>
              <Select
                value={selectedTherapist}
                onChange={(e) => setSelectedTherapist(e.target.value)}
                label="Therapist"
              >
                {therapistList.length === 0 ? (
                  <MenuItem disabled>No therapists available</MenuItem>
                ) : (
                  therapistList.map((therapist) => (
                    <MenuItem key={therapist.id} value={therapist.id}>
                      {therapist.name} ({therapist.gender})
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} container justifyContent="center">
            <Button type="submit" variant="contained" color="primary">
              Assign Therapist
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default TherapistChange;

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import LoaderComponent from '../../components/common/LoaderComponent/LoaderComponent';
import './OrderView.style.css';

import {
  Pagination,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  IconButton,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Button,
} from '@mui/material';

import { Search } from '@mui/icons-material';
import { getClientOrderPaymentDetails } from '../../store/actions/payment.action';

const PaymentOrderInfo = () => {
  const searchInfo = JSON.parse(localStorage.getItem('searchInfo')) ?? null;

  const navigate = useNavigate();
  const limit = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [client, setClient] = useState({
    clientList: [],
    clientDetails: null,
    totalClient: 0,
    isLoading: false,
    totalPages: 1,
  });

  const [search, setSearch] = useState({
    type: searchInfo?.type ?? 'contactNumber',
    text: searchInfo?.text ?? '',
    searchClick: false,
  });

  const handlePageChange = (event, value) => {
    if (value >= 1 && value <= client.totalPages) {
      setCurrentPage(value);
    }
  };

  const getOrderDetails = async () => {
    setClient((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    localStorage.setItem('searchInfo', JSON.stringify(search));

    try {
      if (search?.type === 'contactNumber' && search?.text?.length > 0) {
        const response = await getClientOrderPaymentDetails(
          limit,
          currentPage,
          search.type,
          search.text,
        );

        const { list, total, user } = response;

        setClient((prevState) => ({
          ...prevState,
          clientList: list,
          clientDetails: user,
          totalClient: total,
          totalPages: Math.ceil(total / limit),
          isLoading: false,
        }));
      } else {
        setClient((prevState) => ({
          ...prevState,
          clientList: [],
          clientDetails: null,
          totalClient: 0,
          totalPages: 0,
          isLoading: false,
        }));
      }
    } catch (error) {
      setClient((prevState) => ({
        ...prevState,
        clientList: [],
        clientDetails: null,
        totalClient: 0,
        totalPages: 0,
        isLoading: false,
      }));
    }
  };

  const handleViewDetails = (order) => {
    try {
      localStorage.setItem('searchInfo', JSON.stringify(search));
      if (order) {
        navigate(`/payments-info/${order.orderId}`, { state: order });
      }
    } catch (error) {}
  };

  useEffect(() => {
    setClient((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    // const debounceTimer = setTimeout(() => {
    getOrderDetails();
    // }, 2000);

    // return () => {
    //   clearTimeout(debounceTimer);
    // };
  }, [currentPage, search.searchClick, search.text]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '30px',
        }}
      >
        <h3 style={{ margin: '0 auto' }}>Order Details</h3>
      </div>
      <SearchComponent
        search={search}
        setSearch={setSearch}
        getData={getOrderDetails}
      />

      {client?.clientDetails && (
        <div style={{ marginTop: '20px', marginBottom: '20px' }}>
          <h4>Client Name: {client?.clientDetails?.name}</h4>
        </div>
      )}
      {client?.isLoading ? (
        <LoaderComponent />
      ) : (
        <>
          <OrderView data={client.clientList} viewDetails={handleViewDetails} />
        </>
      )}

      <div className="incentiv-pagination" style={{ marginTop: '1rem' }}>
        <Stack spacing={3}>
          <Pagination
            count={client.totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
          />
        </Stack>
      </div>
    </>
  );
};

export default PaymentOrderInfo;

const SearchComponent = ({ search, setSearch, getData }) => {
  const handleSearch = () => {
    if (search.text.trim().length < 1) {
      return;
    }
    setSearch((prevState) => ({
      ...prevState,
      searchClick: !prevState.searchClick,
    }));
    getData();
  };

  const handleChange = (event) => {
    const type = event.target.value;
    setSearch((prevState) => ({
      ...prevState,
      text: '',
      type: type,
    }));
  };

  const handleSearchOnClick = (e) => {
    if (search.text.trim().length < 1) {
      return;
    }
    if (e.key === 'Enter') {
      e.preventDefault();
      setSearch((prevState) => ({
        ...prevState,
        searchClick: !prevState.searchClick,
      }));
      getData();
    }
  };

  const handleTextChange = (e) => {
    const text = e.target.value;
    setSearch((prevState) => ({
      ...prevState,
      text: text,
    }));
  };

  return (
    <>
      <FormControl
        sx={{ minWidth: 200, marginBottom: 2, marginRight: 2 }}
        size="small"
      >
        <InputLabel id="demo-simple-select-standard-label">
          Filter Type
        </InputLabel>
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={search.type}
          label="searchType"
          onChange={handleChange}
        >
          <MenuItem value="contactNumber">Contact Number</MenuItem>
        </Select>
      </FormControl>

      <TextField
        placeholder="Search"
        variant="outlined"
        size="small"
        value={search.text}
        type={search.type === 'contactNumber' ? 'number' : 'text'}
        required={true}
        onChange={handleTextChange}
        onKeyDown={handleSearchOnClick}
        InputProps={{
          endAdornment: (
            <IconButton color="primary" onClick={handleSearch}>
              <Search />
            </IconButton>
          ),
        }}
        sx={{ marginBottom: 2 }}
      />
    </>
  );
};

const OrderView = ({ data, viewDetails }) => {
  const navigate = useNavigate();

  const handleSubmitDetails = (order) => {
    viewDetails(order);
  };

  const handleViewDetails = (order) => {
    navigate(`/payments-info/order/${order.orderId}`);
  };

  return (
    <div className="table-container">
      {data && data.length > 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow className="table-header">
                <TableCell className="table-cell" rowSpan={2}>
                  orderId
                </TableCell>
                <TableCell className="table-cell" rowSpan={2}>
                  Order Amount
                </TableCell>
                <TableCell className="table-cell">orderDetailId</TableCell>
                <TableCell className="table-cell">productName</TableCell>
                <TableCell className="table-cell">sessionCount</TableCell>
                <TableCell className="table-cell">productGender</TableCell>
                <TableCell className="table-cell" rowSpan={2}>
                  Payment Proof
                </TableCell>
                <TableCell className="table-cell" rowSpan={2}>
                  Add payment
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {data.map((order) =>
                order.orderDetails.map((detail, index) => (
                  <TableRow key={detail.orderDetailId}>
                    {index === 0 ? (
                      <>
                        <TableCell
                          className="table-cell"
                          rowSpan={order.orderDetails.length}
                        >
                          {order.orderId}
                        </TableCell>
                        <TableCell
                          className="table-cell"
                          rowSpan={order.orderDetails.length}
                        >
                          {order.orderAmount}
                        </TableCell>
                      </>
                    ) : null}

                    <TableCell className="table-cell">
                      {detail.orderDetailId}
                    </TableCell>
                    <TableCell className="table-cell">
                      {detail.productName}
                    </TableCell>
                    <TableCell className="table-cell">
                      {detail.sessionCount}
                    </TableCell>
                    <TableCell className="table-cell">
                      {detail.productGender}
                    </TableCell>

                    {index === 0 ? (
                      <>
                        <TableCell
                          className="table-cell"
                          rowSpan={order.orderDetails.length}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleViewDetails(order)}
                          >
                            View
                          </Button>
                        </TableCell>

                        <TableCell
                          className="table-cell"
                          rowSpan={order.orderDetails.length}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleSubmitDetails(order)}
                          >
                            Add payment
                          </Button>
                        </TableCell>
                      </>
                    ) : null}
                  </TableRow>
                )),
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div className="no-data-found">
          <p>Data not found</p>
        </div>
      )}
    </div>
  );
};

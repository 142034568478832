import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../../components/common/userLocalStorageUtils';

const apiUrl = process.env.REACT_APP_SCHEDULER_API_URL;
const apiUrlBackend = process.env.REACT_APP_API_URL;

export const fetchTherapist = createAsyncThunk('therapist', async (_, { rejectWithValue }) => {
  try {
    // const res = await axios.get(`${apiUrl}/api/v1/therapist/get-all-therapists`);
    const res = await axios.get(`${apiUrl}/api/v1/therapist/get-all-center-therapists`);
    return res?.data;
  } catch (error) {
    return rejectWithValue('Failed to fetch Machines. Please try again later.');
  }
});


export const addTherapist = async (data) => {
  try {
    const res = await axios.post(`${apiUrl}/api/v1/therapist/add-therapist`, data);
    return res;
  } catch (error) {
    return error;
  }

};

export const fetchTherapistCustomslots = createAsyncThunk('therapist-custom-slot', async (_, { rejectWithValue }) => {
  try {
    const res = await axios.get(`${apiUrl}/api/v1/custom-therapist-slot/get-all-therapist-custom-slots`);
    return res?.data;
  } catch (error) {
    return rejectWithValue('Failed to fetch Centers. Please try again later.');
  }
});


export const UpdateTherapist = async (id, data) => {
  try {
    const res = await axios.put(`${apiUrl}/api/v1/therapist/update-therapist/${id}`, data);
    return res;
  } catch (error) {
    return error;
  }
}




export const updateCustomTherapistSlot = async (id, data) => {
  try {
    const res = await axios.put(`${apiUrl}/api/v1/custom-therapist-slot/update-therapist-custom-slot/${id}`, data);
    return res;
  } catch (error) {
    return error;
  }
}

export const fetchTherapistRecord = createAsyncThunk('therapist/record', async (therapistId, { rejectWithValue }) => {
  try {
    const res = await axios.get(`${apiUrl}/api/v1/custom-therapist-slot/get-therapist-custom-slots/${therapistId}`);
    return res?.data?.slotDetails;
  } catch (error) {
    return rejectWithValue('Failed to fetch Machines. Please try again later.');
  }
});


export const fetchAvailableTherapist = createAsyncThunk('available/therapist', async (body, { rejectWithValue }) => {
  try {
    const res = await axios.post(`${apiUrl}/api/v1/allocate/candidate-therapist-list`, body);
    return res?.data?.therapistList
      ;
  } catch (error) {
    return rejectWithValue('Failed to available fetch Therapists. Please try again later.');
  }
});

export const reAllocateTherapist = async (data) => {
  try {
    const res = await axios.post(`${apiUrl}/api/v1/allocate/allot-therapist`, data);
    return res;
  } catch (error) {
    return error;
  }
}


export const manualTherapistAllocation = async (data) => {
  try {
    const res = await axios.post(`${apiUrl}/api/v1/allocate/manual-therapist-allot`, data);
    return res;
  } catch (error) {
    return error;
  }
}

export const getClientSlots = async (data) => {
  try {
    const res = await axios.post(`${apiUrl}/api/v1/slot/get-client-slots`, data);
    return res;
  } catch (error) {
    return error;
  }
}


export const confirmClientSlots = async (data) => {
  try {
    const res = await axios.post(`${apiUrl}/api/v1/allocate/reschedule-booking`, data);
    return res;
  } catch (error) {
    return error;
  }
}

export const cancelBooking = async (reqData) => {
  try {
    let userData = localStorage.getItem("userData")
    userData = JSON.parse(userData)

    const dashboardUserId = userData?.user?.id
    const dashboardUserName = userData?.user?.name
    const data = {
      ...reqData,
      dashboardUserId,
      dashboardUserName

    }
    const res = await axios.post(`${apiUrl}/api/v1/allocate/cancel-booking`, data);
    return res;
  } catch (error) {
    return error;
  }
}


export const autoAllocateTherapistAndMachine = async (data) => {
  try {
    const res = await axios.post(`${apiUrl}/api/v1/allocate/auto-allocate`, data);
    return res;
  } catch (error) {
    return error;
  }
}

export const addTherapistUnavailabilityAndLeave = async (
  addTherapistUnavailabilityAndLeaveBody
) => {
  try {
    const res = await axios.post(
      `${apiUrl}/api/v1/custom-therapist-slot/add-therapist-custom-slot`,
      addTherapistUnavailabilityAndLeaveBody
    );
    return res.data;
  } catch (err) {
    return err.response.data.status.message;
  }
};

export const markTherapistFree = async (data) => {
  try {
    const res = await axios.post(`${apiUrl}/api/v1/allocate/mark-therapist-available`, data);
    return res;
  } catch (error) {
    return error;
  }
}


export const deAllocateTherapist = async (data) => {
  try {
    const res = await axios.post(`${apiUrl}/api/v1/allocate/deallocate-therapist`, data);
    return res;
  } catch (error) {
    return error;
  }
}

export const markTherapistWeekOffFree = async (data) => {
  try {
    const res = await axios.post(`${apiUrl}/api/v1/allocate/mark-therapist-weekoff-available`, data);
    return res;
  } catch (error) {
    return error;
  }
}


export const fetchTherapistServiceAbleProducts = createAsyncThunk('therapist/serviceableProductp', async (id, { rejectWithValue }) => {
  try {
    const res = await axios.get(`${apiUrl}/api/v1/therapist/get-therapist-serviceable-product/${id}`);
    return res?.data;
  } catch (error) {
    return rejectWithValue('Failed to fetch therapist. Please try again later.');
  }
});


export const deleteTherapist = async (therapistId) => {
  try {
    const res = await axios.patch(`${apiUrl}/api/v1/therapist/delete-therapist/${therapistId}`);
    return res?.data;
  } catch (error) {
    return error;
  }
}

export const deleteTherapistProduct = async (id) => {
  try {
    const res = await axios.patch(`${apiUrl}/api/v1/therapist/delete-therapist-product/${id}`);
    return res?.data;
  } catch (error) {
    return error;
  }
}

export const addMultiProductsToTherapist = async (therapistId, products) => {
  try {
    const response = await axios.post(`${apiUrl}/api/v1/therapist/add-multi-products`, {
      therapistId: Number(therapistId), products: products.map((p) => Number(p.productId)),
    });
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || error.message;
  }
};

export const removeProductsFromTherapist = async (therapistId, productId) => {
  try {
    const response = await axios.patch(`${apiUrl}/api/v1/therapist/delete-therapist-product`, {
      therapistId: Number(therapistId),
      productId: Number(productId),
    });
    return response.data;
  } catch (error) {
    throw error?.response?.data?.message || error.message;
  }
}

export const getFailedTherapistAllocationBookingList = async (id) => {
  try {
    const res = await axios.get(`${apiUrl}/api/v1/custom-therapist-slot/failed-therapist-allocation-booking/${id}`);
    return res.data;
  } catch (error) {
    console.error("Failed to fetch therapist allocation data:", error);
    throw error;
  }
};

export const storeTherapistAvailabilityLog = async (body) => {
  try {
    const res = await axios.post(`${apiUrlBackend}/api/v1/logs/therapist-availability-logs`,
      body,
      {
        headers: {
          Authorization: `Basic ${getToken()}`,
          token: getToken(),
        }
      }
    );

    const data = res.data;
    return data;
  }
  catch (err) {
    throw err;
  }
}

export const storeAddTherapistLog = async (body) => {
  try {
    const res = await axios.post(`${apiUrlBackend}/api/v1/logs/add-therapist-logs`,
      body,
      {
        headers: {
          Authorization: `Basic ${getToken()}`,
          token: getToken(),
        }
      }
    )

    const data = res.data;
    return data;
  }
  catch (err) {
    throw err;
  }
}

export const storeTherapistMachineProductsLogs = async (body) => {
  try {
    const res = await axios.post(`${apiUrlBackend}/api/v1/logs/add-therapist-machine-products-logs`,
      body,
      {
        headers: {
          Authorization: `Basic ${getToken()}`,
          token: getToken(),
        }
      }
    )

    const data = res.data;
    return data;
  }
  catch (err) {
    throw err;
  }
}
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Typography,
  TextField,
  Button,
  Grid,
  Box,
  Paper,
  Divider,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import { getToken } from '../userLocalStorageUtils';
import {
  addMachine,
  storeAddMachineLogs,
} from '../../../store/actions/machine.action';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCenter } from '../../../store/actions/center.action';
import { useNavigate } from 'react-router-dom';

const AddMachine = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let centers = useSelector((state) => state.center?.centerList?.centers);

  const [loading, setLoading] = useState(false);
  const [machineDetails, setMachineDetails] = useState({
    name: '',
    centerId: '',
    uniqueMachineCode: '',
    products: [],
  });

  const [availability, setAvailability] = useState({
    mondayAvailability: { startTime: '', endTime: '' },
    tuesdayAvailability: { startTime: '', endTime: '' },
    wednesdayAvailability: { startTime: '', endTime: '' },
    thursdayAvailability: { startTime: '', endTime: '' },
    fridayAvailability: { startTime: '', endTime: '' },
    saturdayAvailability: { startTime: '', endTime: '' },
    sundayAvailability: { startTime: '', endTime: '' },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMachineDetails({
      ...machineDetails,
      [name]: value,
    });
  };

  const handleAvailabilityChange = (day, field, value) => {
    setAvailability((prevState) => ({
      ...prevState,
      [day]: {
        ...prevState[day],
        [field]: value,
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !machineDetails.name ||
      !machineDetails.centerId ||
      !machineDetails.uniqueMachineCode
    ) {
      alert('Please fill all required machine details.');
      return;
    }
    for (const day of Object.keys(availability)) {
      const dayAvailability = availability[day];
      if (
        dayAvailability !== null &&
        Array.isArray(dayAvailability) === false
      ) {
        if (!dayAvailability.startTime || !dayAvailability.endTime) {
          alert(
            `Please select both start time and end time for ${day.replace(
              'Availability',
              '',
            )}.`,
          );
          return;
        }

        if (dayAvailability.startTime >= dayAvailability.endTime) {
          alert(
            `End time must be later than start time for ${day.replace(
              'Availability',
              '',
            )}.`,
          );
          return;
        }
      }
    }

    const payload = {
      ...machineDetails,
      products: JSON.stringify(machineDetails.products),
      ...availability,
    };

    setLoading(true);
    const res = await addMachine(payload);
    if (res?.status === 200) {
      const user = JSON.parse(localStorage.getItem('userData'))?.user;
      if (user) {
        const obj = {
          adminName: user.name,
          adminId: user.id,
          machineId: payload.uniqueMachineCode,
          machineName: payload.name,
        };
        await storeAddMachineLogs(obj);
      }
      alert(res.data.status?.message);
      navigate('/machinelist');
    }
    setLoading(false);
  };

  const renderTimePicker = (dayKey, label, field) => (
    <TextField
      label={label}
      select
      value={availability[dayKey]?.[field] || ''}
      onChange={(e) => handleAvailabilityChange(dayKey, field, e.target.value)}
      InputLabelProps={{ shrink: true }}
      fullWidth
      required
    >
      {/* Time options from 07:00 to 22:00 in 30-minute intervals */}
      {Array.from({ length: 31 }, (_, i) => {
        const hour = String(Math.floor((i + 14) / 2)).padStart(2, '0'); // Start from 07:00
        const minutes = (i + 14) % 2 === 0 ? '00' : '30';
        const timeValue = `${hour}:${minutes}`;
        return (
          <MenuItem key={timeValue} value={timeValue}>
            {timeValue}
          </MenuItem>
        );
      })}
    </TextField>
  );
  useEffect(() => {
    dispatch(fetchCenter());
  }, [dispatch]);

  return (
    <Paper
      elevation={4}
      style={{ padding: '30px', marginTop: '30px', backgroundColor: '#f0f4f7' }}
    >
      <Typography
        variant="h4"
        gutterBottom
        style={{
          color: '#0077b6',
          fontWeight: 'bold',
          textAlign: 'center',
          marginBottom: '20px',
        }}
      >
        Add New Machine Info
      </Typography>

      <Box marginBottom={4}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <TextField
                label="Machine Name"
                name="name"
                value={machineDetails.name}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                select
                label="Center Name"
                name="centerId"
                value={machineDetails.centerId}
                onChange={handleChange}
                fullWidth
                required
              >
                {centers?.map((center) => (
                  <MenuItem key={center} value={center.id}>
                    {center.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="Unique Machine Code"
                name="uniqueMachineCode"
                value={machineDetails.uniqueMachineCode}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
          </Grid>

          <Divider style={{ margin: '30px 0', backgroundColor: '#0077b6' }} />

          {/* Availability Section */}
          <Grid container spacing={4}>
            {Object.keys(availability).map((dayKey) => (
              <Grid item xs={12} md={6} key={dayKey}>
                <Box
                  padding="20px"
                  borderRadius="8px"
                  bgcolor="#ffffff"
                  sx={{ boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.1)' }}
                >
                  <Typography
                    variant="h6"
                    gutterBottom
                    style={{
                      textTransform: 'capitalize',
                      color: '#023047',
                      fontWeight: '600',
                    }}
                  >
                    {dayKey.replace('Availability', '')}
                  </Typography>
                  <Grid container spacing={2} style={{ marginTop: '10px' }}>
                    <Grid item xs={6}>
                      {renderTimePicker(dayKey, 'Start Time', 'startTime')}
                    </Grid>
                    <Grid item xs={6}>
                      {renderTimePicker(dayKey, 'End Time', 'endTime')}
                    </Grid>
                  </Grid>
                  <Divider style={{ margin: '10px 0' }} />
                </Box>
              </Grid>
            ))}
          </Grid>

          <Divider style={{ margin: '30px 0', backgroundColor: '#0077b6' }} />

          <Button
            type="submit"
            variant="contained"
            style={{
              backgroundColor: '#0077b6',
              color: '#ffffff',
              padding: '10px 20px',
              fontWeight: 'bold',
              fontSize: '16px',
              display: 'block',
              margin: '0 auto',
            }}
          >
            {loading ? <CircularProgress size={24} /> : 'Submit'}
          </Button>
        </form>
      </Box>
    </Paper>
  );
};

export default AddMachine;

import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from '@mui/material';
import axios from 'axios';
import { getToken } from '../../components/common/userLocalStorageUtils';
import TableComponent from '../../components/common/TableComponent/TableComponent';
import DownloadIcon from '@mui/icons-material/Download';
import GetAppIcon from '@mui/icons-material/GetApp';
import { Search } from '@material-ui/icons';

const apiUrl = process.env.REACT_APP_API_URL;

const getDefaultDates = () => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  return {
    startDate: yesterday.toISOString().split('T')[0],
    endDate: tomorrow.toISOString().split('T')[0],
  };
};


export default function PartnerLogs() {
    const [data,setData]=useState([]);

    const storedStartDate = localStorage.getItem('startDate');
      const storedEndDate = localStorage.getItem('endDate');
    
      const { startDate: defaultStart, endDate: defaultEnd } = getDefaultDates();
    
      const [count, setCount] = useState(0);
      const [page, setPage] = useState(0);
      const [limit, setLimit] = useState(10);
      const [startDate, setStartDate] = useState(storedStartDate || defaultStart);
      const [endDate, setEndDate] = useState(storedEndDate || defaultEnd);
      const [loading, setLoading] = useState(false);
    
      const [search, setSearch] = useState({
        type: '',
        text: '',
        searchClick: false,
        startDate: startDate,
        endDate: endDate,
      });

    const fetchData=async()=>{
        try{
            setLoading(true);
            const response=await axios.get(`${apiUrl}/api/v1/admin/wati/get-reschedule-data`,
                {
                    params:{
                        page,
                        limit,
                        start_date:search.startDate,
                        end_date:search.endDate,
                        client_phone:search.type==='clientPhone'?search.text:''
                    },
                    headers:{
                        Authorization: `Basic ${getToken()}`,
                        token: getToken(),
                    }
                }
            )
            if (response?.status === 200) {
                setCount(response.data.data.count)
                setData(response.data.data.data);
            }
        }
        catch(err){
            console.error(err);
            throw err;
        }
        finally{
            setLoading(false);
        }
    }
    
    useEffect(()=>{
        fetchData();
    },[page,limit,search.startDate,search.endDate])

  return (
    <div>
        <h3>Wati Reschedules</h3>
       {loading?
       <h3 style={{textAlign:'center'}}>Loading...</h3>
       :<> 
            <SearchComponent search={search}
                setSearch={setSearch}
                getData={fetchData}/>
            <TableComponent data={data} hiddenFields={["deletedAt","updatedAt"]}/>
        </>}
        <TablePagination
            component="div"
            count={count}
            page={page}
            rowsPerPage={limit}
            onPageChange={(_, newPage) => {
              setPage(newPage);
            }}
            onRowsPerPageChange={(e) => {
              setLimit(parseInt(e.target.value, 10));
              setPage(0);
            }}
          />
    </div>
  )
}


const SearchComponent = ({ search, setSearch, getData }) => {
  const handleSearch = () => {
    if (search.text.trim().length < 1) {
      return;
    }
    setSearch((prevState) => ({
      ...prevState,
      searchClick: !prevState.searchClick,
    }));
    getData();
  };

  const handleChange = (event) => {
    const type = event.target.value;
    setSearch((prevState) => ({
      ...prevState,
      text: '',
      type: type,
    }));
  };

  const handleSearchOnClick = (e) => {
    if (search.text.trim().length < 1) {
      return;
    }
    if (e.key === 'Enter') {
      e.preventDefault();
      setSearch((prevState) => ({
        ...prevState,
        searchClick: !prevState.searchClick,
      }));
      getData();
    }
  };

  const handleTextChange = (e) => {
    const text = e.target.value;
    setSearch((prevState) => ({
      ...prevState,
      text: text,
    }));
  };

  const handleDateChange = (event) => {
    const { name, value } = event.target;

    if (name === 'startDate' || name === 'endDate') {
      setSearch((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const downloadData = async (e, format) => {
    try {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/v1/admin/wati/download-reschedule-data`,
          {
            params: {
              start_date: search.startDate,
              end_date: search.endDate,
            },
            headers: {
              Authorization: `Basic ${getToken()}`,
              token: getToken(),
            },
          },
        )
        .then((res) => res.data)
        .then((data) => {
          if (data?.status.code === 200 && data?.downloadLinks) {
            const link =
              format === 'csv'
                ? data.downloadLinks.csv
                : data.downloadLinks.excel;
            if (link) {
              window.location.href = link;
            } else {
              alert('No valid download link available');
            }
          } else {
            alert('Error occurred while generating download link');
          }
        });
    } catch (e) {
      alert('Error occurred while generating download link');
    }
  };

  return (
    <div className="filter-window">
      <div className="window-1">
        <FormControl sx={{ minWidth: 200, mb: 2, mr: 2 }} size="small">
          <InputLabel id="filter-label">Filter Type</InputLabel>
          <Select
            labelId="filter-label"
            id="filter"
            value={search.type}
            label="searchType"
            onChange={handleChange}
          >
            {/* <MenuItem value="callerPhone">Caller Phone</MenuItem> */}
            <MenuItem value="clientPhone">Client Phone</MenuItem>
          </Select>
        </FormControl>

        <TextField
          placeholder="Search"
          variant="outlined"
          size="small"
          value={search.text}
          type={search.type === 'contactNumber' ? 'number' : 'text'}
          required
          onChange={handleTextChange}
          onKeyDown={handleSearchOnClick}
          InputProps={{
            endAdornment: (
              <IconButton color="primary" onClick={handleSearch}>
                <Search />
              </IconButton>
            ),
          }}
          sx={{ mb: 2 }}
        />
      </div>
      <div className="window-2">
        <input
          type="date"
          name="startDate"
          value={search.startDate || ''}
          onChange={handleDateChange}
        />
        <input
          type="date"
          name="endDate"
          value={search.endDate || ''}
          onChange={handleDateChange}
          min={search.startDate ? search.startDate : ''}
        />

        <Button
          variant="contained"
          color="primary"
          startIcon={<DownloadIcon />}
          onClick={(e) => downloadData(e, 'csv')}
        >
          CSV
        </Button>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<GetAppIcon />}
          onClick={(e) => downloadData(e, 'excel')}
        >
          Excel
        </Button>
      </div>
    </div>
  );
};
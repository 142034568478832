import React, { useEffect, useState } from 'react';
import TableComponent from '../../components/common/TableComponent/TableComponent';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { listPackages } from '../../store/actions/packages.action';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getToken } from '../../components/common/userLocalStorageUtils';

const apiUrl = process.env.REACT_APP_BACKEND_API_URL;

const SkincareProducts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [products, setProducts] = useState([]);


  const fetchData = async () => {
    try {
      const res = await axios.get(`${apiUrl}/skincare-products`, {
        headers: {
          Authorization: `Basic ${getToken()}`,
          token: getToken(),
        },
      });
      const data = await res.data.data;
      setProducts(data);
    } catch (error) {
      console.error('Error in Partner:', error);
      throw error;
    }
  };

  const handleEdit = (e) => {
    localStorage.setItem('productEdit', e.id);
    localStorage.setItem('productDetail', 'edit');
    navigate('add-product');
  };

  const handlePagination = (event) => {
    const pageNumber = parseInt(event.target.innerHTML.split('')[0], 10);
    setPage(pageNumber);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    dispatch(listPackages(page));
  }, [dispatch, page]);

  return (
    <div>
      <h3>Skincare Products</h3>
      <Button
        concentrixUservariant="contained"
        color="primary"
        style={{
          margin: '10px',
          backgroundColor: '#384456',
          color: 'white',
          transition: 'transform 0.3s,background-color 0.3s',
        }}
        onMouseOver={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
        onMouseOut={(e) => (e.currentTarget.style.transform = 'scale(1)')}
        onClick={() => {
          navigate('/skincare-products/add-product');
          localStorage.setItem('productDetail', 'add');
        }}
      >
        Add Product
      </Button>

      <TableComponent
        hiddenFields={['description', 'discountedPrice']}
        data={products}
        viewButton={'Edit'}
        viewDetails={handleEdit}
      />

      <div style={{ marginTop: '20px', float: 'right' }}>
        <Pagination
          count={Math.ceil(products.length / 10)}
          color="primary"
          onChange={handlePagination}
        />
      </div>
    </div>
  );
};

export default SkincareProducts;

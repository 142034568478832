import axios from 'axios';
import { useEffect, useState } from 'react';
import { getToken } from '../../components/common/userLocalStorageUtils';

import {
  Box,
  Typography,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Paper,
  TableCell,
  TableBody,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  IconButton,
} from '@mui/material';
import { Search } from '@mui/icons-material';

const OTPs = () => {
  const [logData, setLogData] = useState([]);


  const fetchCustomerSupport = async () => {

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/admin/client/get-otp`,
        {
          headers: { token: getToken(), Authorization: `Basic ${getToken()}`, },
        },
      );
      const data = response.data;

      if (data?.status?.code == 200) {
        setLogData(data.list);
      }
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    fetchCustomerSupport();
  }, []);

  return (
    <>
      <Typography variant="h4" align="center" gutterBottom>
        OTP
      </Typography>

      <TableContainer
        component={Paper}
        style={{ maxWidth: '100vw', overflowX: 'auto', maxHeight: '580px' }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>id</TableCell>
              <TableCell>OTP</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {logData?.map((row, index) =>
            (
              <TableRow key={index} style={{ fontSize: '12px' }}>
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.action.split(" ")[0]} - {row.action.split(" ")[1]}</TableCell>
              </TableRow>
            ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default OTPs;
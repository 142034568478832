import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getPaymentsByOrder } from '../../store/actions/payment.action';
import LoaderComponent from '../../components/common/LoaderComponent/LoaderComponent';

import './OrderView.style.css';
import {
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import ChangeStatus from './Common/ChangeStatus';
import { Stack } from '@mui/system';
import ProofViewer from './Common/ProofViewer';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import dayjs from 'dayjs';

const PaymentProofSubmittedByOrder = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const limit = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const [data, setData] = useState({
    list: [],
    total: 0,
    isLoading: false,
    totalPages: 1,
  });

  const getData = async () => {
    setData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    try {
      const response = await getPaymentsByOrder(id, limit, currentPage);

      setData((prevState) => ({
        ...prevState,
        list: response.list,
        total: response.total,
        totalPages: Math.ceil(response.total / limit),
        isLoading: false,
      }));
    } catch (error) {
      setData((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }
  };

  const handlePageChange = (event, value) => {
    if (value >= 1 && value <= data.totalPages) {
      setCurrentPage(value);
    }
  };

  useEffect(() => {
    getData();
  }, [id, currentPage]);
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '30px',
        }}
      >
        <IconButton color="primary" onClick={() => navigate(-1)}>
          <KeyboardBackspaceIcon />
        </IconButton>
        <h3 style={{ margin: '0 auto' }}>Payment Info</h3>
      </div>

      {data.isLoading ? (
        <LoaderComponent />
      ) : (
        <PaymentProofView data={data.list} />
      )}

      <div className="incentiv-pagination" style={{ marginTop: '1rem' }}>
        <Stack spacing={3}>
          <Pagination
            count={data.totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
          />
        </Stack>
      </div>
    </>
  );
};

export default PaymentProofSubmittedByOrder;

const PaymentProofView = ({ data }) => {
  return (
    <div className="table-container">
      {data && data?.length > 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow className="table-header">
                <TableCell className="table-cell">Order Detail Id</TableCell>
                <TableCell className="table-cell">Mode</TableCell>
                <TableCell className="table-cell">Payment Id</TableCell>

                <TableCell className="table-cell">Payment Date</TableCell>
                <TableCell className="table-cell">Amount</TableCell>
                <TableCell className="table-cell">Status</TableCell>
                <TableCell className="table-cell">Submitted By</TableCell>
                <TableCell className="table-cell">Approved By</TableCell>
                <TableCell className="table-cell">Reconciled By</TableCell>
                <TableCell className="table-cell">Proof</TableCell>
                <TableCell className="table-cell">Remarks</TableCell>
                <TableCell className="table-cell">Edit</TableCell>
              </TableRow>
              <TableRow></TableRow>
            </TableHead>

            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell className="table-cell">
                    {row.orderDetailId}
                  </TableCell>
                  <TableCell className="table-cell">{row.mode}</TableCell>
                  <TableCell className="table-cell">{row.paymentId}</TableCell>
                  {/* <TableCell className="table-cell">
                    {row.paymentVendor}
                  </TableCell> */}
                  <TableCell className="table-cell">
                    {dayjs(row?.createdAt).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell className="table-cell">{row.amount}</TableCell>
                  <TableCell className="table-cell">{row.status}</TableCell>
                  <TableCell className="table-cell">
                    {row.submittedBy}
                  </TableCell>
                  <TableCell className="table-cell">{row.approvedBy}</TableCell>
                  <TableCell className="table-cell">
                    {row.reconciledBy}
                  </TableCell>
                  <TableCell className="table-cell">
                    <ProofViewer files={row.proof} />
                  </TableCell>
                  <TableCell className="table-cell">{row.remarks}</TableCell>
                  <TableCell className="table-cell">
                    <ChangeStatus row={row} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div className="no-data-found">
          <p>Data not found</p>
        </div>
      )}
    </div>
  );
};

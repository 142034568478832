import React, { useState, useEffect } from 'react';
import {
    Container,
    TextField,
    Button,
    TablePagination,
    CircularProgress,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Stack
} from '@mui/material';
import axios from 'axios';
import { getToken } from '../../components/common/userLocalStorageUtils';

import DownloadIcon from '@mui/icons-material/Download';

// Default start and end dates (March 1st to March 31st)
const getDefaultDates = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensure two-digit format

    return {
        startDate: `${year}-${month}-01`,
        endDate: `${year}-${month}-${new Date(year, month, 0).getDate()}`,
    };
};

const UpsellPotentialUsers = () => {
    const { startDate: defaultStart, endDate: defaultEnd } = getDefaultDates();

    const [users, setUsers] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [startDate, setStartDate] = useState(defaultStart);
    const [endDate, setEndDate] = useState(defaultEnd);
    const [therapistPhone, setTherapistPhone] = useState('');
    const [loading, setLoading] = useState(false);

    const fetchUpsellUsers = async () => {
        if (!startDate || !endDate) return;
        setLoading(true);
        try {
            const { data } = await axios.get(
                `${process.env.REACT_APP_API_URL}/api/v1/admin/upsell/potential-users`,
                {
                    params: {
                        start_date: startDate,
                        end_date: endDate,
                        page: page + 1,
                        limit,
                        therapistPhone: therapistPhone || undefined,
                    },
                    headers: {
                        Authorization: `Basic ${getToken()}`,
                        token: getToken(),
                    },
                }
            );

            setUsers(data.users);
            setCount(data.count);
        } catch (error) {
            console.error('Error fetching upsell users:', error);
        } finally {
            setLoading(false);
        }
    };

    const exportUpsellUsers = async () => {
        if (!startDate || !endDate) {
            alert('Please select both start and end dates.');
            return;
        }

        const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/admin/upsell/export-potentail-users`;

        const params = { startDate, endDate, page: page + 1, limit: 10_000_000 };
        const headers = {
            Authorization: `Basic ${getToken()}`,
            token: getToken(),
        };

        try {
            const { data } = await axios.get(apiUrl, { params, headers });

            if (data?.status && data?.downloadLinks?.csv) {
                window.location.href = data.downloadLinks.csv;
            } else {
                alert('Error occurred while generating download link');
            }
        } catch (error) {
            console.error('Export error:', error);
            alert('An error occurred while generating the download link');
        }
    };


    useEffect(() => {
        fetchUpsellUsers();
    }, [startDate, endDate, page, limit]);

    return (
        <Container>

            <Stack direction="row" spacing={2} mb={3} style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                <h2 style={{ margin: '20px 0' }}>Upsell Users</h2>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<DownloadIcon />}
                    onClick={(e) => exportUpsellUsers(e)}
                >
                    Export
                </Button>
            </Stack>



            {/* Filters */}
            <div style={{ display: 'flex', gap: '15px', marginBottom: '20px' }}>
                <TextField
                    label="Start Date"
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    label="End Date"
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    label="Therapist Phone"
                    type="tel"
                    value={therapistPhone}
                    onChange={(e) => setTherapistPhone(e.target.value)}
                    placeholder="Enter 10-digit phone"
                    inputProps={{ maxLength: 10 }}
                />
                <Button variant="contained" color="primary" onClick={fetchUpsellUsers}>
                    Search
                </Button>
            </div>

            {/* Table or Loader */}
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="300px">
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell><b>Client Name</b></TableCell>
                                    <TableCell><b>Client Phone</b></TableCell>
                                    <TableCell><b>Booking ID</b></TableCell>
                                    <TableCell><b>Product Name</b></TableCell>
                                    <TableCell><b>City</b></TableCell>
                                    <TableCell><b>Therapist Name</b></TableCell>
                                    <TableCell><b>Therapist Phone</b></TableCell>
                                    <TableCell><b>Appointment Date</b></TableCell>
                                    <TableCell><b>Upsell Potential</b></TableCell>
                                    <TableCell><b>Interested Services</b></TableCell>
                                    <TableCell><b>Remark</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {users.length > 0 ? (
                                    users.map((user, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{user.clientName}</TableCell>
                                            <TableCell>{user.clientPhone}</TableCell>
                                            <TableCell>{user.bookingId}</TableCell>
                                            <TableCell>{user.productName}</TableCell>
                                            <TableCell>{user.city}</TableCell>
                                            <TableCell>{user.therapistName}</TableCell>
                                            <TableCell>{user.therapistPhone}</TableCell>
                                            <TableCell>{new Date(user.appointmentDate).toLocaleString()}</TableCell>
                                            <TableCell>{user.upsellPotential ? 'Yes' : 'No'}</TableCell>
                                            <TableCell>{user.interestedServices.join(', ') || 'N/A'}</TableCell>
                                            <TableCell>{user.remark || 'N/A'}</TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={10} align="center">
                                            No data found
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {/* Pagination */}
                    <TablePagination
                        component="div"
                        count={count}
                        page={page}
                        rowsPerPage={limit}
                        onPageChange={(_, newPage) => setPage(newPage)}
                        onRowsPerPageChange={(e) => {
                            setLimit(parseInt(e.target.value, 10));
                            setPage(0);
                        }}
                    />
                </>
            )}
        </Container>
    );
};

export default UpsellPotentialUsers;

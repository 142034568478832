import React, { useEffect, useState } from 'react'
import TableComponent from '../../components/common/TableComponent/TableComponent';
import axios from 'axios';
import { getToken } from '../../components/common/userLocalStorageUtils';
import { TablePagination } from '@mui/material';

const apiUrl = process.env.REACT_APP_API_URL;
export default function PartnerLogs() {
    const [data,setData]=useState([]);
    const [loading,setLoading]=useState(false);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const stringifiedUser = localStorage.getItem('userData');
    const userData = stringifiedUser ? JSON.parse(stringifiedUser) : null;
    const if_the_user_is_vaibhav = userData?.user?.email === 'vaibhav.sharma@avataarskin.com'
    const fetchData=async()=>{
        try{
            setLoading(true);
            const response=await axios.get(`${apiUrl}/api/v1/logs/get-partner-logs`,
                {
                    params:{
                        page,
                        limit
                    },
                    headers:{
                        Authorization: `Basic ${getToken()}`,
                        token: getToken(),
                    }
                }
            )
            if (response?.status === 200) {
                const res = response.data.data.map((item) => ({
                    ...item,
                    createdAt: new Date(item.createdAt).toLocaleString(),
                }));
                setCount(response.data.totalCount)
                setData(res);
            }
        }
        catch(err){
            console.error(err);
            throw err;
        }
        finally{
            setLoading(false);
        }
    }
    
    useEffect(()=>{
        if(if_the_user_is_vaibhav)
            fetchData();
    },[page,limit])

    if(!if_the_user_is_vaibhav){
        return <h1>:no_pedestrians: Area Restricted</h1>;
    }

  return (
    <div>
        <h3>Partner Logs</h3>
       {loading?
       <h3 style={{textAlign:'center'}}>Loading...</h3>
       : <TableComponent data={data} hiddenFields={["deletedAt","updatedAt"]}/>}
        <TablePagination
            component="div"
            count={count}
            page={page}
            rowsPerPage={limit}
            onPageChange={(_, newPage) => {
              setPage(newPage);
            }}
            onRowsPerPageChange={(e) => {
              setLimit(parseInt(e.target.value, 10));
              setPage(0);
            }}
          />
    </div>
  )
}










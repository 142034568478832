import axios from "axios";
import { getToken } from "../../components/common/userLocalStorageUtils";

const apiUrl = process.env.REACT_APP_API_URL;

export const getDoctor = async (limit, page, type, text) => {
    try {
        let search = "";
        if (type && text) {
            search = `&${type}=${text}`
        }
        const res = await
            axios.get(`${apiUrl}/api/v1/admin/doctor/doctors-list?limit=${limit}&page=${page}${search}`,
                {
                    headers: {
                        Authorization: `Basic ${getToken()}`,
                        token: getToken()
                    },
                }
            );

        const { status, data } = await res.data;
        if (status.code == 200 && data?.list) {

            const fieldsToExclude = ["token", "createdAt", "updatedAt", "deletedAt"];
            const filteredData = data.list.map(({ ...item }) => {
                fieldsToExclude.forEach((field) => delete item[field]);
                return item;
            });
            return {
                list: filteredData,
                total: data.total || 0,
            };
        }
        return { list: [], total: 0 };
    }
    catch (error) {
        throw error;
    }
};

export const addDoctor = async (data) => {
    try {
        const res = await axios.post(`${apiUrl}/api/v1/admin/doctor/add-doctor`, data, {
            headers: {
                Authorization: `Basic ${getToken()}`,
                token: getToken(),
            },
        })
        const response = await res.data;
        return response
    } catch (error) {
        throw error;
    }
};

export const getDoctorById = async (id) => {
    try {
        const res = await axios.get(`${apiUrl}/api/v1/admin/doctor/single-doctor/${id}`, {
            headers: {
                Authorization: `Basic ${getToken()}`,
                token: getToken(),
            },
        })
        const response = await res.data;
        return response;
    } catch (error) {
        throw error;
    }
}

export const updateDoctorById = async (id, data) => {
    try {
        const res = await axios.put(`${apiUrl}/api/v1/admin/doctor/update-doctor/${id}`, data, {
            headers: {
                Authorization: `Basic ${getToken()}`,
                token: getToken(),
            },
        })
        const response = await res.data;
        return response
    } catch (error) {
        throw error;
    }
}

export const deleteDoctorById = async (id) => {
    try {
        const res = await axios.delete(`${apiUrl}/api/v1/admin/doctor/delete-doctor/${id}`, {
            headers: {
                Authorization: `Basic ${getToken()}`,
                token: getToken(),
            },
        })
        const response = await res.data;
        return response;
    } catch (error) {
        throw error;
    }
}

export const exportDoctor = async (limit, page, type, text) => {
    try {
        const res = await axios.get(`${apiUrl}/api/v1/admin/doctor/export-doctor`, {
            headers: {
                Authorization: `Basic ${getToken()}`,
                token: getToken(),
            },
        })
        const response = await res.data;
        return response;
    } catch (error) {
        throw error;
    }
}

export const storeDoctorDeleteLog = async (body) => {
    try {
        const res = await axios.post(`${apiUrl}/api/v1/logs/delete-doctor-logs`,
            body,
            {
                headers: {
                    Authorization: `Basic ${getToken()}`,
                    token: getToken(),
                }
            });
        const data = res.data;
        console.log("see logs", data)
        return data;
    }
    catch (err) {
        throw err;
    }
}